import React, { useState, useCallback, useEffect, useRef } from "react";
import { useSpring, animated, config } from "react-spring";
import "./MultiplierWheel.css";

const MultiplierWheel = ({ options = [], value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const wheelRef = useRef(null);

  const toggleWheel = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wheelRef.current && !wheelRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const buttonAnimation = useSpring({
    transform: isOpen ? "translateY(4px)" : "translateY(0px)",
    config: config.stiff,
  });

  const optionsAnimation = useSpring({
    opacity: isOpen ? 1 : 0,
    transform: isOpen ? "scale(1)" : "scale(0)",
    config: config.wobbly,
  });

  const backgroundAnimation = useSpring({
    opacity: isOpen ? 0.8 : 0,
    transform: isOpen ? "scale(1)" : "scale(0)",
    config: config.wobbly,
  });

  // Create spring animations for each option
  const optionAnimations = options.map((_, index) => {
    const rotation = (360 / options.length) * index;
    return useSpring({
      opacity: isOpen ? 1 : 0,
      transform: isOpen
        ? `rotate(${rotation}deg) translateY(-100px) rotate(-${rotation}deg)`
        : `rotate(${rotation}deg) translateY(0px) rotate(-${rotation}deg)`,
      config: config.wobbly,
      delay: index * 50,
    });
  });

  return (
    <div className="multiplier-wheel-container" ref={wheelRef}>
      <animated.button
        className={`multiplier-button ${isOpen ? "open" : ""}`}
        onClick={toggleWheel}
        style={buttonAnimation}
      >
        x{value}
      </animated.button>
      <animated.div
        className="multiplier-background"
        style={backgroundAnimation}
      />
      <animated.div className="multiplier-options" style={optionsAnimation}>
        {options &&
          options.length > 0 &&
          options.map((option, index) => {
            const springProps = optionAnimations[index];

            return (
              <animated.button
                key={option.value}
                className={`multiplier-option ${
                  option.value === value ? "selected" : ""
                } ${option.value >= 120 ? "purple" : "orange"}`}
                onClick={() => {
                  onChange(option.value);
                  setIsOpen(false);
                }}
                style={{
                  ...springProps,
                  "--rotation": `${(360 / options.length) * index}deg`,
                }}
              >
                x{option.value}
              </animated.button>
            );
          })}
      </animated.div>
    </div>
  );
};

export default MultiplierWheel;
