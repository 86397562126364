import React, { useState, useEffect, useMemo } from "react";
import { TILE_NAMES } from "./constants";

const ProgressBar = ({
  current,
  total,
  targetPositions,
  calculatedPath,
  selectedCalculationMultiplier,
}) => {
  const [animatedPercentage, setAnimatedPercentage] = useState(0);
  const targetPercentage = (current / total) * 100;

  const { targetsHit, totalTargetHits, targetHits } = useMemo(() => {
    const hits = calculatedPath
      .slice(0, current)
      .filter(
        (step) =>
          targetPositions.includes(step.position) &&
          step.multiplier === selectedCalculationMultiplier
      ).length;

    const totalHits = calculatedPath.filter(
      (step) =>
        targetPositions.includes(step.position) &&
        step.multiplier === selectedCalculationMultiplier
    ).length;

    const allHits = calculatedPath
      .map((step, index) => {
        const isTargetHit =
          targetPositions.includes(step.position) &&
          step.multiplier === selectedCalculationMultiplier;
        return isTargetHit ? { index, position: step.position } : null;
      })
      .filter((hit) => hit !== null);

    return {
      targetsHit: hits,
      totalTargetHits: totalHits,
      targetHits: allHits,
    };
  }, [calculatedPath, current, targetPositions, selectedCalculationMultiplier]);

  useEffect(() => {
    let animationFrameId;
    const startTime = performance.now();
    const animationDuration = 1; // 0.5 seconds, adjust as needed
    const startPercentage = animatedPercentage;

    const animate = (currentTime) => {
      const elapsedTime = currentTime - startTime;
      if (elapsedTime < animationDuration) {
        const progress = elapsedTime / animationDuration;
        const newPercentage =
          startPercentage + (targetPercentage - startPercentage) * progress;
        setAnimatedPercentage(newPercentage);
        animationFrameId = requestAnimationFrame(animate);
      } else {
        setAnimatedPercentage(targetPercentage);
      }
    };

    animationFrameId = requestAnimationFrame(animate);

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [targetPercentage, calculatedPath]);

  return (
    <div className="progress-bar-wrapper">
      <div className="progress-bar-container">
        <div
          className="progress-bar"
          style={{ width: `${animatedPercentage}%` }}
        ></div>
        {targetHits.map((hit) => (
          <div
            key={hit.index}
            className="target-hit-marker"
            style={{ left: `${(hit.index / (total - 1)) * 100}%` }}
            title={`Target Hit: ${
              TILE_NAMES[hit.position]
            } (x${selectedCalculationMultiplier})`}
          />
        ))}
      </div>
      <div className="progress-text">
        Steps {current} / {total} • Targets (x{selectedCalculationMultiplier}){" "}
        {targetsHit} / {totalTargetHits}
      </div>
    </div>
  );
};

export default React.memo(ProgressBar);
