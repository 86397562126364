import React, { useState, useEffect } from "react";
import { spacePositions } from "./constants";

const MonopolyBoard = ({
  currentPosition,
  targetPositions,
  onSpaceClick,
  isSelectingStart,
  isSelectingTargets,
  isAdjustingPosition,
  isCalculated,
  token,
  onRollClick,
  onPreviousClick,
  currentStepIndex,
  calculatedPath,

  
}) => {
  const [hoveredSpace, setHoveredSpace] = useState(null);
  useEffect(() => {
    console.log('MonopolyBoard updated. Current position:', currentPosition);
  }, [currentPosition]);

  const handleClick = (index) => {
    if (!isCalculated || isAdjustingPosition || isSelectingTargets) {
      onSpaceClick(index);
    }
  };

  return (
    <div className="board-container">
      <div className="board-wrapper">
        <img
          src="/MGs_Board.svg"
          alt="Monopoly Board"
          className="board-image"
        />
        <svg viewBox="0 0 1000 1000" className="board-svg">
          {spacePositions.map((pos, index) => (
            <React.Fragment key={index}>
              <rect
                className={`
                  rotated-tile
                  ${hoveredSpace === index ? "hovered" : ""}
                  ${isAdjustingPosition ? "adjusting" : ""}
                  ${isSelectingTargets ? "selecting-targets" : ""}
                  ${isCalculated && !isAdjustingPosition && !isSelectingTargets ? "calculated" : ""}
                  ${currentPosition === index ? "current-position" : ""}
                  ${targetPositions.includes(index) ? "target-position" : ""}
                `}
                x={pos.x}
                y={pos.y}
                width={pos.width}
                height={pos.height}
                onClick={() => handleClick(index)}
                onMouseEnter={() => setHoveredSpace(index)}
                onMouseLeave={() => setHoveredSpace(null)}
                style={{
                  cursor:
                    isCalculated && !isAdjustingPosition && !isSelectingTargets
                      ? "default"
                      : "pointer",
                }}
              />
            </React.Fragment>
          ))}
          {currentPosition !== null && (
            <image
              className="token-tile"
              x={spacePositions[currentPosition].x}
              y={spacePositions[currentPosition].y}
              width={spacePositions[currentPosition].width}
              height={spacePositions[currentPosition].height}
              href={token}
              style={{
                cursor:
                  isCalculated && !isAdjustingPosition && !isSelectingTargets
                    ? "default"
                    : "pointer",
              }}
              onClick={() => handleClick(currentPosition)}
            />
          )}
        </svg>
      </div>
      {isCalculated && (
        <div className="roll-controls">
          <button
            className="roll-button"
            onClick={onRollClick}
            disabled={currentStepIndex === calculatedPath.length - 1}
          >
            ROLL
          </button>
          <button
            className="previous-button"
            onClick={onPreviousClick}
            disabled={currentStepIndex === -1}
          >
            PREVIOUS
          </button>
        </div>
      )}
    </div>
  );
};

export default MonopolyBoard;