import React from "react";
import { useSpring, animated } from "react-spring";
import "./FeedbackModal.css";

const FeedbackModal = ({ isOpen, message, onClose, isError = true }) => {
  const animation = useSpring({
    opacity: isOpen ? 1 : 0,
    transform: isOpen ? "scale(1)" : "scale(0.5)",
    config: { tension: 300, friction: 10 },
  });

  if (!isOpen) return null;

  const title = isError ? "Error" : "Success";
  const modalClass = isError ? "error-modal" : "success-modal";

  return (
    <div className="feedback-modal-overlay">
      <animated.div
        style={animation}
        className={`feedback-modal ${modalClass}`}
      >
        <h2>{title}</h2>
        <p>{message}</p>
        <button onClick={onClose}>Close</button>
      </animated.div>
    </div>
  );
};

export default FeedbackModal;
