import Papa from "papaparse";
import { TILE_NAMES, MULTIPLIERS } from "./constants";

export const exportGameStateToCSV = (
  rolls,
  startingPosition,
  currentPosition,
  targetPositions,
  usedMultipliers,
  selectedToken
) => {
  // Validation checks
  if (startingPosition === null || startingPosition === undefined) {
    throw new Error("Starting position must be selected before exporting.");
  }

  if (!targetPositions || targetPositions.length === 0) {
    throw new Error(
      "At least one target position must be selected before exporting."
    );
  }

  const csvData = [];

  // Add game setup information
  csvData.push(["Game Setup"]);
  csvData.push(["Starting Position", TILE_NAMES[startingPosition]]);
  csvData.push(["Current Position", TILE_NAMES[currentPosition]]);
  csvData.push([
    "Target Positions",
    targetPositions.map((pos) => TILE_NAMES[pos]).join(", "),
  ]);
  csvData.push(["Used Multipliers", Array.from(usedMultipliers).join(", ")]);
  csvData.push(["Selected Token", selectedToken]);
  csvData.push([]); // Empty row for separation

  // Add rolls data
  csvData.push(["Rolls"]);
  csvData.push(["Roll#", "Multiplier", "Roll Value", "From", "To"]);

  // Function to add a roll to csvData
  const addRollToCsv = (roll) => {
    csvData.push([
      roll.roll,
      roll.multiplier,
      roll.rollValue,
      TILE_NAMES[roll.from],
      TILE_NAMES[roll.to],
    ]);
  };

  // Handle different roll data structures
  if (Array.isArray(rolls)) {
    if (rolls.length > 0 && Array.isArray(rolls[0])) {
      // Nested array structure
      rolls.forEach((group, index) => {
        if (Array.isArray(group)) {
          group.forEach((roll) => addRollToCsv(roll));
        } else {
          addRollToCsv(group);
        }
      });
    } else {
      // Flat array structure
      rolls.forEach((roll) => addRollToCsv(roll));
    }
  } else if (typeof rolls === "object") {
    // Object structure
    Object.values(rolls).forEach((group) => {
      if (Array.isArray(group)) {
        group.forEach((roll) => addRollToCsv(roll));
      } else {
        addRollToCsv(group);
      }
    });
  }

  return csvData;
};

export const handleFileUpload = (
  event,
  setRolls,
  setPositions,
  setStartingPosition,
  setCurrentPosition,
  setUsedMultipliers,
  setTargetPositions,
  setSelectedToken,
  setActiveMultipliers,
  setCurrentMultiplier,
  setImportStatus,
  setIsSelectingStart,
  setIsErrorModalOpen,
  setErrorMessage
) => {
  return new Promise((resolve, reject) => {
    const file = event.target.files[0];
    let missingStartPosition = false;
    let missingTargets = false;

    Papa.parse(file, {
      complete: (result) => {
        const data = result.data;
        let gameSetup = {};
        let groupedRolls = {};

        try {
          for (let i = 1; i < data.length; i++) {
            if (data[i][0] === "Rolls") break;
            switch (data[i][0]) {
              case "Starting Position":
                gameSetup.startingPosition = Object.keys(TILE_NAMES).find(
                  (key) => TILE_NAMES[key] === data[i][1]
                );
                break;
              case "Current Position":
                gameSetup.currentPosition = Object.keys(TILE_NAMES).find(
                  (key) => TILE_NAMES[key] === data[i][1]
                );
                break;
              case "Target Positions":
                gameSetup.targetPositions = data[i][1]
                  .split(", ")
                  .map((name) =>
                    Object.keys(TILE_NAMES).find(
                      (key) => TILE_NAMES[key] === name
                    )
                  )
                  .filter((pos) => pos !== undefined);
                break;
              case "Used Multipliers":
                gameSetup.usedMultipliers = data[i][1]
                  .split(", ")
                  .map(Number)
                  .filter((num) => !isNaN(num));
                break;
              case "Selected Token":
                gameSetup.selectedToken = data[i][1];
                break;
            }
          }

          if (!gameSetup.startingPosition) {
            missingStartPosition = true;
          }
          if (
            !gameSetup.targetPositions ||
            gameSetup.targetPositions.length === 0
          ) {
            missingTargets = true;
          }

          const rollsStartIndex = data.findIndex((row) => row[0] === "Rolls");
          if (rollsStartIndex === -1)
            throw new Error("Rolls data not found in CSV");

          for (let i = rollsStartIndex + 2; i < data.length; i++) {
            if (data[i].length < 5) continue;
            const [rollNumber, multiplier, rollValue, from, to] = data[i];

            if (
              isNaN(Number(rollNumber)) ||
              isNaN(Number(multiplier)) ||
              isNaN(Number(rollValue))
            ) {
              continue;
            }

            const roll = {
              roll: Number(rollNumber),
              multiplier: Number(multiplier),
              rollValue: Number(rollValue),
              from:
                Object.keys(TILE_NAMES).find(
                  (key) => TILE_NAMES[key] === from
                ) || null,
              to:
                Object.keys(TILE_NAMES).find((key) => TILE_NAMES[key] === to) ||
                null,
            };

            if (!groupedRolls[rollNumber]) {
              groupedRolls[rollNumber] = [];
            }
            groupedRolls[rollNumber].push(roll);
          }

          const rollsArray = Object.values(groupedRolls);
          const allUsedMultipliers = new Set(
            rollsArray.flat().map((roll) => Number(roll.multiplier))
          );

          setRolls(rollsArray);
          setStartingPosition(Number(gameSetup.startingPosition));
          setUsedMultipliers(allUsedMultipliers);
          const activeMultipliersArray = Array.from(allUsedMultipliers);
          setActiveMultipliers(activeMultipliersArray);
          setTargetPositions(gameSetup.targetPositions.map(Number));
          setSelectedToken(gameSetup.selectedToken);

          const positions = {};
          let lastRollPosition = Number(gameSetup.startingPosition);
          allUsedMultipliers.forEach((multiplier) => {
            const lastRoll = rollsArray
              .flat()
              .filter((roll) => Number(roll.multiplier) === multiplier)
              .pop();
            if (lastRoll) {
              positions[multiplier] = Number(lastRoll.to);
              if (multiplier === activeMultipliersArray[0]) {
                lastRollPosition = Number(lastRoll.to);
              }
            }
          });
          setPositions(positions);

          const currentMultiplier = activeMultipliersArray[0] || 1;
          setCurrentMultiplier(currentMultiplier);
          setCurrentPosition(lastRollPosition);
          setIsSelectingStart(false);

          setImportStatus("Import successful!");
          resolve({ missingStartPosition, missingTargets });
        } catch (error) {
          setImportStatus(`Error: ${error.message}`);
          reject(error);
        }
      },
      error: (error) => {
        setImportStatus(`Error parsing CSV: ${error.message}`);
        reject(error);
      },
    });
  });
};
