import React from "react";
import "./CalculationResults.css";

const CalculationResults = ({
  calculatedPath,
  targetPositions,
  selectedCalculationMultiplier,
}) => {
  if (!calculatedPath || calculatedPath.length === 0) {
    return <p>No calculation results available.</p>;
  }

  return (
    <div className="calculation-results">
      <h3>Calculated Path</h3>
      <table>
        <thead>
          <tr>
            <th>Step</th>
            <th>Multiplier</th>
            <th>Roll Value</th>
          </tr>
        </thead>
        <tbody>
          {calculatedPath.map((step, index) => {
            const isTargetHit =
              (step.tileName !== undefined ||
                targetPositions.includes(step.position)) &&
              step.multiplier === selectedCalculationMultiplier;
            return (
              <tr
                key={index}
                className={`multiplier-${step.multiplier} ${
                  isTargetHit ? "target-hit" : ""
                }`}
              >
                <td>{step.step}</td>
                <td>x{step.multiplier}</td>
                <td>{step.rollValue}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default React.memo(CalculationResults);
