export const removeRoll = (
  rolls,
  rollToRemove,
  setRolls,
  setPositions,
  setCurrentPosition,
  setUsedMultipliers,
  setActiveMultipliers,
  startingPosition
) => {
  setRolls((prevRolls) => {
    // Flatten the rolls array
    let flatRolls = prevRolls.flat();

    // Remove the roll with the specified roll number
    flatRolls = flatRolls.filter((roll) => roll.roll !== rollToRemove.roll);

    // Group rolls by multiplier
    const rollsByMultiplier = flatRolls.reduce((acc, roll) => {
      if (!acc[roll.multiplier]) {
        acc[roll.multiplier] = [];
      }
      acc[roll.multiplier].push(roll);
      return acc;
    }, {});

    // Renumber rolls for each multiplier separately
    Object.keys(rollsByMultiplier).forEach((multiplier) => {
      rollsByMultiplier[multiplier] = rollsByMultiplier[multiplier].map(
        (roll, index) => ({
          ...roll,
          roll: index + 1,
        })
      );
    });

    // Flatten the rolls back into a single array
    flatRolls = Object.values(rollsByMultiplier).flat();

    // Sort the flattened array by the original roll number to maintain overall order
    flatRolls.sort((a, b) => a.roll - b.roll);

    // Group the rolls by their roll number
    const groupedRolls = flatRolls.reduce((acc, roll) => {
      if (!acc[roll.roll - 1]) {
        acc[roll.roll - 1] = [];
      }
      acc[roll.roll - 1].push(roll);
      return acc;
    }, []);

    // Update positions
    const newPositions = { ...startingPosition };
    flatRolls.forEach((roll) => {
      newPositions[roll.multiplier] = roll.to;
    });
    setPositions(newPositions);

    // Update current position
    if (flatRolls.length > 0) {
      setCurrentPosition(flatRolls[flatRolls.length - 1].to);
    } else {
      setCurrentPosition(startingPosition);
    }

    // Update used multipliers and active multipliers
    const multipliers = new Set(flatRolls.map((roll) => roll.multiplier));
    setUsedMultipliers(multipliers);
    setActiveMultipliers(Array.from(multipliers));

    return groupedRolls;
  });
};
