export const handleUndoLastRoll = (
  rolls,
  positions,
  currentMultiplier,
  startingPosition
) => {
  if (rolls.length === 0) {
    return null;
  }

  let updatedRolls = [...rolls];
  let updatedPositions = { ...positions };
  let updatedCurrentPosition = startingPosition;
  let updatedUsedMultipliers = new Set();
  let removedRoll = null;

  // Find the last roll for the current multiplier
  for (let i = updatedRolls.length - 1; i >= 0; i--) {
    const currentMultiplierIndex = updatedRolls[i].findIndex(
      (roll) => roll.multiplier === currentMultiplier
    );

    if (currentMultiplierIndex !== -1) {
      removedRoll = updatedRolls[i][currentMultiplierIndex];
      updatedRolls[i].splice(currentMultiplierIndex, 1);

      // If this group is now empty, remove it
      if (updatedRolls[i].length === 0) {
        updatedRolls.splice(i, 1);
      }
      break;
    }
  }

  // If no roll was found for the current multiplier, return null
  if (!removedRoll) {
    return null;
  }

  // Update positions and used multipliers
  updatedRolls.flat().forEach((roll) => {
    updatedPositions[roll.multiplier] = roll.to;
    updatedUsedMultipliers.add(roll.multiplier);
  });

  // Update the current position for the current multiplier
  const lastRollForCurrentMultiplier = updatedRolls.flat().reverse().find(
    (roll) => roll.multiplier === currentMultiplier
  );
  updatedCurrentPosition = lastRollForCurrentMultiplier 
    ? lastRollForCurrentMultiplier.to 
    : startingPosition;

  updatedPositions[currentMultiplier] = updatedCurrentPosition;

  return {
    updatedRolls,
    updatedPositions,
    updatedCurrentPosition,
    updatedUsedMultipliers: Array.from(updatedUsedMultipliers),
    removedRoll
  };
};