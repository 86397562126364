import React, { useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import './Toast.css';

const Toast = ({ message, onClose }) => {
  const props = useSpring({
    opacity: 1,
    transform: 'translate(-50%, -50%) scale(1)',
    from: { opacity: 0, transform: 'translate(-50%, -50%) scale(0.8)' },
    config: { tension: 280, friction: 60 },
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 3000);

    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <animated.div className="toast" style={props}>
      {message}
    </animated.div>
  );
};

export default Toast;