import React from "react";
import "./ClearRollsConfirmation.css";
import { useSpring, animated } from "react-spring";

const ClearRollsConfirmation = ({ isOpen, onClose, onConfirm }) => {
  const animation = useSpring({
    from: { opacity: 0, transform: "scale(0.8)" },
    to: {
      opacity: isOpen ? 1 : 0,
      transform: isOpen ? "scale(1)" : "scale(0.5)",
    },
    config: { tension: 300, friction: 10 },
  });

  if (!isOpen) return null;

  return (
    <div className="clear-rolls-overlay">
      <animated.div style={animation} className="clear-rolls-modal">
        <h2>Confirm Clear Rolls</h2>
        <p>Are you sure you want to clear all rolls?</p>
        <div className="clear-rolls-buttons">
          <button className="clear-rolls-no" onClick={onClose}>
            No
          </button>
          <button className="clear-rolls-yes" onClick={onConfirm}>
            Yes
          </button>
        </div>
      </animated.div>
    </div>
  );
};

export default ClearRollsConfirmation;
