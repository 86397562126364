import React, {
  useContext,
  useEffect,
  useCallback,
  useState,
  useMemo,
  useRef,
} from "react";
import { GameContext } from "./GameContext";
import MonopolyBoard from "./MonopolyBoard";
import CustomSelect from "./CustomSelect";
import "./styles.css";
import { CSVLink } from "react-csv";
import { calculateRollPath } from "./calculateRollPath";
import { handleUndoLastRoll } from "./undoFunction";
import {
  handleMoveToRailroad,
  handleMoveToRailroadCalculatedPath,
  findNearestRailroad,
} from "./railroadHelpers";
import { exportGameStateToCSV, handleFileUpload } from "./csvHelpers";
import {
  handleSpaceClick as originalHandleSpaceClick,
  handleMultiplierChange,
  handleRollClear,
  addRollToState,
} from "./monopolyHelpers";
import { handleKeyDown } from "./eventHandlers";
import {
  TOKEN_NAMES,
  MULTIPLIERS,
  TILE_NAMES,
  CHANCE_POSITIONS,
  SPACES,
} from "./constants";
import { debounce } from "lodash";
import CalculationResults from "./CalculationResults";
import Modal from "./Modal";
import FeedbackModal from "./FeedbackModal";
import ClearRollsConfirmation from "./ClearRollsConfirmation";
import { removeRoll } from "./removeRoll";
import ResetConfirmation from "./ResetConfirmation";
import RollInfo from "./RollInfo";
import MultiplierManager from "./MultiplierManager";
import Papa from "papaparse";
import MultiplierWheel from "./MultiplierWheel";
import ProgressBar from "./ProgressBar";
import CalculationMultiplierWheel from "./CalculationMultiplierWheel";
import { useSpring, animated, config, useTransition } from "react-spring";
import "./StepInfo.css";
import StepInfo from "./StepInfo";
import { useSwipeable } from "react-swipeable";
import RemoveRollsConfirmation from "./RemoveRollsConfirmation";
import { removePreviousRolls } from "./removePreviousRolls";
import Toast from './Toast';
import { GlobalHotKeys } from "react-hotkeys";

const MonopolyGoLogger = () => {
  const {
    startingPosition,
    setStartingPosition,
    currentPosition,
    setCurrentPosition,
    rolls,
    setRolls,
    positions,
    setPositions,
    currentMultiplier,
    setCurrentMultiplier,
    isSelectingStart,
    setIsSelectingStart,
    isSelectingTargets,
    setIsSelectingTargets,
    targetPositions,
    setTargetPositions,
    usedMultipliers,
    setUsedMultipliers,
    activeMultipliers,
    setActiveMultipliers,
    selectedCalculationMultiplier,
    setSelectedCalculationMultiplier,
    calculatedPath,
    setCalculatedPath,
    selectedToken,
    setSelectedToken,
  } = useContext(GameContext);

  const [importStatus, setImportStatus] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentStepIndex, setCurrentStepIndex] = useState(-1);
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [isFeedbackError, setIsFeedbackError] = useState(true);
  const [showClearConfirmation, setShowClearConfirmation] = useState(false);
  const [showResetConfirmation, setShowResetConfirmation] = useState(false);
  const [isAdjustingPosition, setIsAdjustingPosition] = useState(false);
  const [isAdjustingTargets, setIsAdjustingTargets] = useState(false);
  const [isCalculated, setIsCalculated] = useState(false);
  const [showChancePrompt, setShowChancePrompt] = useState(false);
  const [allMultipliers, setAllMultipliers] = useState(MULTIPLIERS);
  const [inactiveMultipliers, setInactiveMultipliers] = useState([]);
  const [isStepInfoVisible, setIsStepInfoVisible] = useState(false);
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [nextCardIndex, setNextCardIndex] = useState(null);
  const [animation, setAnimation] = useState("");
  const [direction, setDirection] = useState(1);
  const fileInputRef = useRef(null);
  const followCalculatedPathRef = useRef(null);
  const boardRef = useRef(null);
  const [originalCalculatedPath, setOriginalCalculatedPath] = useState([]);
  const [showRemoveRollsConfirmation, setShowRemoveRollsConfirmation] =
    useState(false);
  const [currentProgressStep, setCurrentProgressStep] = useState(0);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [manualRollValue, setManualRollValue] = useState("");
  const [isManualInputActive, setIsManualInputActive] = useState(false);
  const [adjustments, setAdjustments] = useState([]);
  const [toastMessage, setToastMessage] = useState('');

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleManualRollSubmit();
    } else if (event.key === "Backspace") {
      setManualRollValue((prev) => prev.slice(0, -1));
    }
  };

  const handleManualRollValueChange = (event) => {
    const value = event.target.value;
    if (
      value === "" ||
      (/^\d{1,2}$/.test(value) && parseInt(value, 10) <= 12)
    ) {
      setManualRollValue(value);
    }
  };

  const inputRef = useRef(null);

  const handleManualRollSubmit = () => {
    if (manualRollValue === "") return;

    const rollValue = parseInt(manualRollValue, 10);
    if (rollValue >= 2 && rollValue <= 12) {
      const fromPosition = positions[currentMultiplier] ?? startingPosition;
      const clickedPosition = (fromPosition + rollValue) % SPACES;
      handleSpaceClick(clickedPosition);
      setManualRollValue("");
      if (inputRef.current) {
        inputRef.current.focus();
      }
    } else {
      setFeedbackMessage(
        "Invalid roll value. Please enter a number between 2 and 12."
      );
      setIsFeedbackError(true);
      setFeedbackModalOpen(true);
    }
  };

  const toggleManualInput = () => {
    if (startingPosition !== null) {
      setIsManualInputActive(!isManualInputActive);
    } else {
      setFeedbackMessage(
        "Please select a starting position before using manual input."
      );
      setIsFeedbackError(true);
      setFeedbackModalOpen(true);
    }
  };

  useEffect(() => {
    const uniqueMultipliers = [
      ...new Set(rolls.flat().map((roll) => roll.multiplier)),
    ];

    setAllMultipliers(uniqueMultipliers);

    setActiveMultipliers((prev) => {
      const prevArray = Array.isArray(prev) ? prev : [];
      const newActiveMultipliers = prevArray.filter((m) =>
        uniqueMultipliers.includes(m)
      );
      return newActiveMultipliers.length > 0
        ? newActiveMultipliers
        : [Math.min(...MULTIPLIERS)];
    });

    if (
      isCalculated &&
      !uniqueMultipliers.includes(selectedCalculationMultiplier)
    ) {
      const validMultipliers = uniqueMultipliers.filter((m) =>
        MULTIPLIERS.includes(m)
      );
      if (validMultipliers.length > 0) {
        setSelectedCalculationMultiplier(Math.max(...validMultipliers));
      } else {
        setSelectedCalculationMultiplier(Math.max(...MULTIPLIERS));
      }
    }
  }, [
    rolls,
    selectedCalculationMultiplier,
    setSelectedCalculationMultiplier,
    isCalculated,
  ]);

  const handleUndoButtonClick = useCallback(() => {
    const undoResult = handleUndoLastRoll(
      rolls,
      positions,
      currentMultiplier,
      startingPosition
    );
  
    if (undoResult) {
      setRolls(undoResult.updatedRolls);
      setPositions(undoResult.updatedPositions);
      setCurrentPosition(undoResult.updatedCurrentPosition);
      setUsedMultipliers(new Set(undoResult.updatedUsedMultipliers));
      setActiveMultipliers(prevActive => 
        prevActive.filter(m => undoResult.updatedUsedMultipliers.includes(m))
      );
  
      // Force a re-render
      setForceUpdate(prev => !prev);
  
      // Show toast notification
      if (undoResult.removedRoll) {
        const { roll, multiplier, rollValue } = undoResult.removedRoll;
        setToastMessage(`Undid Roll #${roll}: ${rollValue} for x${multiplier}`);
        setTimeout(() => setToastMessage(''), 4000);
      }
  
      // Log the updated state
      console.log('After undo:', {
        currentPosition: undoResult.updatedCurrentPosition,
        rolls: undoResult.updatedRolls,
        positions: undoResult.updatedPositions
      });
    }
  }, [
    rolls,
    positions,
    currentMultiplier,
    startingPosition,
    setRolls,
    setPositions,
    setCurrentPosition,
    setUsedMultipliers,
    setActiveMultipliers,
    setToastMessage,
    setForceUpdate
  ]);

  const handleCloseToast = useCallback(() => {
    setToastMessage('');
  }, []);

  const keyMap = {
    UNDO: "ctrl+z",
  };

  const handlers = {
    UNDO: (event) => {
      console.log("Ctrl+Z detected via react-hotkeys, attempting undo");
      event.preventDefault();
      handleUndoButtonClick();
    },
  };

  const handlePositionAdjustment = useCallback(
    (newPosition) => {
      if (isAdjustingPosition) {
        setAdjustments((prev) => [
          ...prev,
          {
            type: "position",
            stepIndex: currentStepIndex,
            originalPosition: currentPosition,
            newPosition: newPosition,
          },
        ]);
        setCurrentPosition(newPosition);
        setIsAdjustingPosition(false);
        if (isCalculated && currentStepIndex >= -1) {
          setCalculatedPath((prev) => {
            let updatedPath = [...prev];
            if (currentStepIndex === -1) {
              updatedPath = calculateRollPath(
                newPosition,
                targetPositions,
                selectedCalculationMultiplier,
                activeMultipliers,
                rolls,
                40
              );
            } else {
              updatedPath[currentStepIndex] = {
                ...updatedPath[currentStepIndex],
                position: newPosition,
                isPositionAdjusted: true,
                originalPosition: currentPosition,
              };

              const remainingRolls = rolls.slice(currentStepIndex);
              const newPath = calculateRollPath(
                newPosition,
                targetPositions,
                selectedCalculationMultiplier,
                activeMultipliers,
                remainingRolls,
                40
              );

              updatedPath = [
                ...updatedPath.slice(0, currentStepIndex),
                ...newPath.map((step, index) => ({
                  ...step,
                  step: currentStepIndex + index + 1,
                })),
              ];
            }
            return updatedPath;
          });
        }
        setForceUpdate((prev) => !prev);
      }
    },
    [
      isAdjustingPosition,
      isCalculated,
      currentStepIndex,
      currentPosition,
      rolls,
      targetPositions,
      selectedCalculationMultiplier,
      activeMultipliers,
      calculateRollPath,
    ]
  );

  const handleTargetAdjustment = useCallback(
    (position) => {
      setIsAdjustingTargets(true);
      setTargetPositions((prevTargets) => {
        // Check if we're trying to deselect the last target
        if (prevTargets.length === 1 && prevTargets.includes(position)) {
          setFeedbackMessage(
            "You can't deselect the last target. At least one target must remain selected for calculations."
          );
          setIsFeedbackError(true);
          setFeedbackModalOpen(true);
          return prevTargets;
        }

        const newTargets = prevTargets.includes(position)
          ? prevTargets.filter((pos) => pos !== position)
          : [...prevTargets, position];

        if (isCalculated && calculatedPath.length > 0) {
          setCalculatedPath((prevPath) => {
            let startPosition;
            let startIndex;

            if (currentStepIndex === -1) {
              startPosition = startingPosition;
              startIndex = 0;
            } else {
              startPosition = currentPosition;
              startIndex = currentStepIndex;
            }

            // Preserve the path up to the current position
            const preservedPath = prevPath.slice(0, startIndex);

            // Calculate remaining rolls
            const remainingRolls = rolls.slice(startIndex);

            // Recalculate the path from the current position onwards
            const newPartialPath = calculateRollPath(
              startPosition,
              newTargets,
              selectedCalculationMultiplier,
              activeMultipliers,
              remainingRolls,
              40
            );

            // Combine preserved path with new partial path
            const updatedPath = [
              ...preservedPath,
              ...newPartialPath.map((step, index) => ({
                ...step,
                step: startIndex + index + 1,
              })),
            ];

            return updatedPath;
          });
        }

        return newTargets;
      });
    },
    [
      isCalculated,
      calculatedPath,
      currentStepIndex,
      startingPosition,
      currentPosition,
      rolls,
      selectedCalculationMultiplier,
      activeMultipliers,
      calculateRollPath,
      setFeedbackMessage,
      setIsFeedbackError,
      setFeedbackModalOpen,
    ]
  );

  const handleTargetToggle = () => {
    setIsSelectingTargets(!isSelectingTargets);
    setIsSelectingStart(false);
  };

  const groupRollsByMultiplier = () => {
    return MULTIPLIERS.reduce((acc, multiplier) => {
      acc[multiplier] = rolls
        .flat()
        .filter((roll) => roll.multiplier === multiplier);
      return acc;
    }, {});
  };

  const rollsByMultiplier = groupRollsByMultiplier();
  const maxRollsLength = Math.max(
    ...MULTIPLIERS.map((multiplier) => rollsByMultiplier[multiplier].length)
  );

  const scrollToBoard = () => {
    if (boardRef.current) {
      boardRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const handlecalculateRollPath = useCallback(
    (
      newActiveMultipliers = activeMultipliers,
      newSelectedCalculationMultiplier = selectedCalculationMultiplier
    ) => {
      setIsSelectingStart(false);
      setIsSelectingTargets(false);
      setIsAdjustingPosition(false);
      setIsAdjustingTargets(false);

      if (startingPosition === null || startingPosition === undefined) {
        setFeedbackMessage("Please select a starting position.");
        setFeedbackModalOpen(true);
        return;
      }
      if (targetPositions.length === 0) {
        setFeedbackMessage("Please select at least one target position.");
        setFeedbackModalOpen(true);
        return;
      }

      const calculationMultipliers = Array.isArray(newActiveMultipliers)
        ? [...newActiveMultipliers]
        : Array.isArray(activeMultipliers)
        ? [...activeMultipliers]
        : MULTIPLIERS;

      if (calculationMultipliers.length === 0) {
        setFeedbackMessage(
          "No active multipliers available for calculation. Please log at least three different multipliers."
        );
        setFeedbackModalOpen(true);
        return;
      }

      try {
        const result = calculateRollPath(
          startingPosition,
          targetPositions,
          newSelectedCalculationMultiplier,
          calculationMultipliers,
          rolls,
          40
        );

        if (result && result.length > 0) {
          setCalculatedPath(result);
          setOriginalCalculatedPath(result);
          setCurrentStepIndex(-1);
          setCurrentPosition(startingPosition);
          setIsCalculated(true);
          setIsStepInfoVisible(true);
        } else {
          setFeedbackMessage(
            "No Path Found. Please log a minimum of 30 rolls and try again."
          );
          setFeedbackModalOpen(true);
        }
      } catch (error) {
        setFeedbackMessage(
          `An error occurred during calculation: ${error.message}`
        );
        setFeedbackModalOpen(true);
      }
    },
    [
      startingPosition,
      targetPositions,
      activeMultipliers,
      selectedCalculationMultiplier,
      rolls,
      setCalculatedPath,
      setCurrentStepIndex,
      setCurrentPosition,
      setIsCalculated,
      setFeedbackMessage,
      setFeedbackModalOpen,
      setIsSelectingStart,
      setIsSelectingTargets,
      setIsAdjustingPosition,
      setIsAdjustingTargets,
    ]
  );

  useEffect(() => {
    if (calculatedPath && calculatedPath.length > 0) {
      if (currentStepIndex === -1) {
        setIsStepInfoVisible(true);
      }
    }
  }, [calculatedPath, currentStepIndex]);
  const handleImportClick = () => {
    document.getElementById("fileInput").click();
  };

  const handleFileInputChange = (event) => {
    setImportStatus("Importing...");
    setIsModalOpen(true);
    handleFileUpload(
      event,
      setRolls,
      setPositions,
      setStartingPosition,
      setCurrentPosition,
      setUsedMultipliers,
      setTargetPositions,
      setSelectedToken,
      setActiveMultipliers,
      setCurrentMultiplier,
      setImportStatus,
      setIsSelectingStart,
      setFeedbackModalOpen,
      setFeedbackMessage
    )
      .then((result) => {
        setIsModalOpen(false);
        let message = "Import successful!";
        if (result.missingStartPosition || result.missingTargets) {
          message += "\n\nHowever, some data was missing:";
          if (result.missingStartPosition) {
            message +=
              "\n- Starting position is missing. Please select a new starting position.";
            setIsSelectingStart(true);
          }
          if (result.missingTargets) {
            message +=
              "\n- Target positions are missing. Please select new target positions.";
            setIsSelectingTargets(true);
          }
        }
        setFeedbackMessage("Import successful!");
        setIsFeedbackError(false);
        setFeedbackModalOpen(true);
      })
      .catch((error) => {
        console.error("Import failed:", error);
        setFeedbackMessage(`Import failed: ${error.message}`);
        setIsFeedbackError(true);
        setFeedbackModalOpen(true);
      })
      .finally(() => {
        setIsModalOpen(false);
      });
  };

  const handleAdjustTargetsClick = useCallback(() => {
    setIsAdjustingTargets((prev) => !prev);
    setIsSelectingTargets((prev) => !prev);
    if (!isAdjustingTargets) {
      scrollToBoard();
    }
  }, [isAdjustingTargets]);

  const toggleTargetPosition = (position) => {
    handleTargetAdjustment(position);
  };

  const handleSpaceClick = useCallback(
    (position) => {
      if (isSelectingStart) {
        setStartingPosition(position);
        setCurrentPosition(position);
        setPositions((prev) => ({
          ...prev,
          [currentMultiplier]: position,
        }));
        setIsSelectingStart(false);
        setShowChancePrompt(false); // Reset chance prompt when selecting start
      } else if (isAdjustingPosition) {
        handlePositionAdjustment(position);
        setShowChancePrompt(false); // Reset chance prompt when adjusting position
      } else if (isSelectingTargets) {
        handleTargetAdjustment(position);
        setShowChancePrompt(false); // Reset chance prompt when selecting targets
      } else if (!isCalculated) {
        try {
          const fromPosition = positions[currentMultiplier] ?? startingPosition;
          const rollValue =
            (position - fromPosition + SPACES) % SPACES || SPACES;
  
          if (rollValue >= 2 && rollValue <= 12) {
            // Calculate the new roll number
            const maxRollForMultiplier = rolls.flat().reduce((max, roll) => 
              roll.multiplier === currentMultiplier ? Math.max(max, roll.roll) : max, 0);
            const newRollNumber = maxRollForMultiplier + 1;
  
            // Create the new roll object
            const newRoll = {
              roll: newRollNumber,
              multiplier: currentMultiplier,
              rollValue,
              from: fromPosition,
              to: position,
            };
  
            // Update rolls state using the new helper function
            setRolls(prevRolls => addRollToState(prevRolls, newRoll));
  
            // Update other states
            setCurrentPosition(position);
            setPositions(prev => ({
              ...prev,
              [currentMultiplier]: position,
            }));
            setUsedMultipliers(prev => new Set(prev).add(currentMultiplier));
  
            // Update showChancePrompt based on the new position
            setShowChancePrompt(CHANCE_POSITIONS.includes(position));
  
            // Update active and all multipliers
            setActiveMultipliers(prev => {
              if (!prev.includes(currentMultiplier)) {
                return [...prev, currentMultiplier].sort((a, b) => b - a);
              }
              return prev;
            });
  
            setAllMultipliers(prev => {
              if (!prev.includes(currentMultiplier)) {
                return [...prev, currentMultiplier].sort((a, b) => b - a);
              }
              return prev;
            });
          } else {
            throw new Error("Invalid move. Rolls must be between 2 and 12.");
          }
        } catch (error) {
          setFeedbackMessage(error.message);
          setFeedbackModalOpen(true);
        }
      } else {
        // If the game is in calculated mode, we should still update the chance prompt
        setShowChancePrompt(CHANCE_POSITIONS.includes(position));
      }
    },
    [
      isSelectingStart,
      isAdjustingPosition,
      isSelectingTargets,
      isCalculated,
      positions,
      currentMultiplier,
      startingPosition,
      rolls,
      SPACES,
      CHANCE_POSITIONS,
      setCurrentPosition,
      setPositions,
      setUsedMultipliers,
      setShowChancePrompt,
      setActiveMultipliers,
      setAllMultipliers,
      setFeedbackMessage,
      setFeedbackModalOpen,
      handlePositionAdjustment,
      handleTargetAdjustment,
    ]
  );

  const handleMoveToRailroadClick = useCallback(() => {
    handleMoveToRailroad(
      currentPosition,
      setCurrentPosition,
      setPositions,
      currentMultiplier,
      setShowChancePrompt
    );
  }, [
    currentPosition,
    setCurrentPosition,
    setPositions,
    currentMultiplier,
    setShowChancePrompt,
  ]);

  const handleMoveToRailroadCalculatedPathClick = useCallback(() => {
    const { newCalculatedPath, railroadPosition } =
      handleMoveToRailroadCalculatedPath(
        currentPosition,
        calculatedPath,
        currentStepIndex,
        rolls,
        targetPositions,
        selectedCalculationMultiplier,
        activeMultipliers
      );

    if (newCalculatedPath) {
      setAdjustments((prev) => [
        ...prev,
        {
          type: "railroad",
          stepIndex: currentStepIndex,
          originalPosition: currentPosition,
          newPosition: railroadPosition,
        },
      ]);
      setCalculatedPath(
        newCalculatedPath.map((step, index) => {
          if (index === currentStepIndex) {
            return {
              ...step,
              isRailroadMove: true,
              originalPosition: currentPosition,
            };
          }
          return step;
        })
      );
      setCurrentPosition(railroadPosition);
      setCurrentStepIndex(currentStepIndex);
      setCurrentProgressStep(currentStepIndex);
      setForceUpdate((prev) => !prev);
    }

    setShowChancePrompt(false);
  }, [
    currentPosition,
    calculatedPath,
    currentStepIndex,
    rolls,
    targetPositions,
    selectedCalculationMultiplier,
    activeMultipliers,
  ]);

  const handleRemovePreviousRolls = useCallback(() => {
    if (currentStepIndex > 0) {
      setShowRemoveRollsConfirmation(true);
    }
  }, [currentStepIndex]);

  const confirmRemovePreviousRolls = useCallback(() => {
    if (currentStepIndex > 0 && calculatedPath.length > currentStepIndex) {
      const newStartingPosition = removePreviousRolls(
        rolls,
        currentStepIndex,
        calculatedPath,
        setRolls,
        setPositions,
        setUsedMultipliers,
        setActiveMultipliers
      );
      setShowRemoveRollsConfirmation(false);
      setCurrentStepIndex(0); // Reset the current step index
      setIsCalculated(false); // Reset the calculation state
      setCalculatedPath([]); // Clear the calculated path
      setStartingPosition(newStartingPosition); // Update the starting position
      setCurrentPosition(calculatedPath[currentStepIndex].position); // Set current position to the position at current step index
    }
  }, [
    currentStepIndex,
    calculatedPath,
    rolls,
    setRolls,
    setPositions,
    setUsedMultipliers,
    setActiveMultipliers,
    setIsCalculated,
    setCalculatedPath,
    setStartingPosition,
    setCurrentPosition,
  ]);

  const TokenSelect = ({ selectedToken, setSelectedToken, tokenOptions }) => {
    return (
      <div className="token-select-wrapper">
        <label htmlFor="token-select">SELECT TOKEN</label>
        <CustomSelect
          id="token-select"
          options={tokenOptions}
          value={selectedToken}
          onChange={setSelectedToken}
          isTokenSelect={true}
        />
      </div>
    );
  };

  const handleToggleMultiplier = useCallback(
    (multiplier) => {
      setActiveMultipliers((prevActive) => {
        let newActive;
        if (prevActive.includes(multiplier)) {
          if (prevActive.length === 1) {
            return prevActive; // Prevent deactivating the last multiplier
          }
          newActive = prevActive.filter((m) => m !== multiplier);
          
          setToastMessage(`Multiplier x${multiplier} turned off`);
          setTimeout(() => setToastMessage(''), 3000);
        } else {
          newActive = [...prevActive, multiplier].sort((a, b) => a - b);
        }
  
        setInactiveMultipliers((prevInactive) => {
          if (prevActive.includes(multiplier)) {
            return [...prevInactive, multiplier].sort((a, b) => a - b);
          } else {
            return prevInactive.filter((m) => m !== multiplier);
          }
        });
  
        let newSelectedMultiplier = selectedCalculationMultiplier;
        if (!newActive.includes(selectedCalculationMultiplier)) {
          newSelectedMultiplier = Math.max(...newActive);
          setToastMessage(`Hit Targets With Multiplier changed to x${newSelectedMultiplier}`);
          setTimeout(() => setToastMessage(''), 3000);
        } else if (multiplier > selectedCalculationMultiplier) {
          newSelectedMultiplier = multiplier;
          setToastMessage(`Hit Targets With Multiplier changed to x${newSelectedMultiplier}`);
          setTimeout(() => setToastMessage(''), 3000);
        }
  
        setSelectedCalculationMultiplier(newSelectedMultiplier);
  
        setTimeout(() => {
          if (isCalculated && calculatedPath.length > 0) {
            let startPosition, remainingRolls;
  
            if (currentStepIndex <= 0) {
              startPosition = startingPosition;
              remainingRolls = rolls;
            } else {
              startPosition = calculatedPath[currentStepIndex - 1].position;
              remainingRolls = rolls.slice(currentStepIndex);
            }
  
            const newPath = calculateRollPath(
              startPosition,
              targetPositions,
              newSelectedMultiplier,
              newActive,
              remainingRolls,
              40
            );
  
            if (newPath && newPath.length > 0) {
              setCalculatedPath((prevPath) => {
                if (currentStepIndex <= 0) {
                  return newPath;
                } else {
                  return [
                    ...prevPath.slice(0, currentStepIndex),
                    ...newPath.map((step, index) => ({
                      ...step,
                      step: currentStepIndex + index,
                    })),
                  ];
                }
              });
            }
          }
        }, 0);
  
        return newActive;
      });
    },
    [
      selectedCalculationMultiplier,
      isCalculated,
      calculatedPath,
      currentStepIndex,
      startingPosition,
      rolls,
      targetPositions,
      calculateRollPath,
    ]
  );

  const handleStartPositionToggle = () => {
    setTimeout(() => setIsSelectingStart(!isSelectingStart), 600);
  };

  const calculationMultiplierOptions = useMemo(() => {
    // Sort activeMultipliers from highest to lowest
    const sortedActiveMultipliers = [...activeMultipliers].sort(
      (b, a) => b - a
    );

    return sortedActiveMultipliers.map((multiplier) => ({
      value: `x${multiplier}`,
      label: `x${multiplier}`,
    }));
  }, [activeMultipliers]);

  const handleAdjustPositionClick = useCallback(() => {
    setIsAdjustingPosition((prev) => !prev);
    setIsAdjustingTargets(false);
    setIsSelectingTargets(false);
    if (!isAdjustingPosition) {
      scrollToBoard();
    }
  }, [isAdjustingPosition]);

  const handleResetClick = () => {
    setShowResetConfirmation(true);
  };

  const handleConfirmReset = () => {
    const result = calculateRollPath(
      startingPosition,
      targetPositions,
      selectedCalculationMultiplier,
      activeMultipliers,
      Array.isArray(rolls[0]) ? rolls : [rolls],
      40
    );

    if (result && result.length > 0) {
      setCalculatedPath(result);
      setCurrentStepIndex(-1);
      setCurrentCardIndex(-1);
      setNextCardIndex(null);
      setAnimation("");
      setCurrentPosition(startingPosition);
      setShowChancePrompt(false);
      setIsCalculated(true);
      setCurrentProgressStep(-1);
    } else {
      setCalculatedPath([]);
      setCurrentStepIndex(-1);
      setCurrentCardIndex(-1);
      setNextCardIndex(null);
      setAnimation("");
      setCurrentPosition(startingPosition);
      setShowChancePrompt(false);
      setIsCalculated(false);
      setCurrentProgressStep(-1);
    }
    setIsAdjustingPosition(false);
    setIsAdjustingTargets(false);
    setIsSelectingTargets(false);
    setIsSelectingStart(false);

    setShowResetConfirmation(false);
  };

  const handleRemoveRoll = useCallback(
    (rollNumber) => {
      const rollToRemove = { roll: rollNumber };
      removeRoll(
        rolls,
        rollToRemove,
        setRolls,
        setPositions,
        setCurrentPosition,
        setUsedMultipliers,
        setActiveMultipliers,
        startingPosition
      );
    },
    [
      rolls,
      setRolls,
      setPositions,
      setCurrentPosition,
      setUsedMultipliers,
      setActiveMultipliers,
      startingPosition,
    ]
  );

  const handleClearRollsClick = () => {
    setShowClearConfirmation(true);
  };

  const handleConfirmClearRolls = () => {
    handleRollClear(
      setRolls,
      setPositions,
      setStartingPosition,
      setCurrentPosition,
      setUsedMultipliers,
      setCurrentMultiplier,
      setSelectedCalculationMultiplier,
      setCalculatedPath,
      setTargetPositions,
      setShowChancePrompt,
      setIsSelectingStart,
      setActiveMultipliers,
      setInactiveMultipliers,
      setIsCalculated,
      setCurrentStepIndex,
      setImportStatus,
      setSelectedToken
    );
    setShowClearConfirmation(false);
  };

  const [{ stepIndex }, setStepIndex] = useSpring(() => ({ stepIndex: -1 }));

  const transitions = useTransition(currentCardIndex, {
    from: {
      opacity: 0,
      transform: `translateX(${100 * direction}%) rotate(45deg)`,
    },
    enter: { opacity: 1, transform: "translateX(0%) rotate(0deg)" },
    leave: {
      opacity: 0,
      transform: `translateX(${-100 * direction}%) rotate(45deg)`,
    },
    config: config.gentle,
  });

  const handleNextStep = useCallback(() => {
    if (calculatedPath && calculatedPath.length > 0) {
      setIsAdjustingTargets(false);
      setIsAdjustingPosition(false);
      setDirection(1);

      setCurrentStepIndex((prevStepIndex) => {
        const newStepIndex = prevStepIndex + 1;

        if (newStepIndex < calculatedPath.length) {
          setCurrentCardIndex(newStepIndex);
          setCurrentProgressStep(newStepIndex);

          // Set current position to one step behind
          if (newStepIndex === 0) {
            setCurrentPosition(startingPosition);
          } else {
            setCurrentPosition(calculatedPath[newStepIndex - 1].position);
          }

          // Check for Chance space on the current token position
          const tokenPosition =
            newStepIndex === 0
              ? startingPosition
              : calculatedPath[newStepIndex - 1].position;
          setShowChancePrompt(CHANCE_POSITIONS.includes(tokenPosition));

          // Force update to ensure all components re-render
          setForceUpdate((prev) => !prev);

          return newStepIndex;
        } else {
          // We've reached the end of the path
          const lastStep = calculatedPath[calculatedPath.length - 1];
          setCurrentPosition(lastStep.position);
          setShowChancePrompt(false);
          return prevStepIndex; // Keep the current step index
        }
      });
    }
  }, [
    calculatedPath,
    setCurrentPosition,
    setShowChancePrompt,
    CHANCE_POSITIONS,
    startingPosition,
  ]);

  const handlePreviousStep = useCallback(() => {
    if (currentStepIndex > 0) {
      const prevIndex = currentStepIndex - 1;

      setCalculatedPath((prevPath) => {
        let updatedPath = [...prevPath];
        const currentStep = updatedPath[currentStepIndex];
        const prevStep = updatedPath[prevIndex];

        let newPosition =
          prevIndex === 0
            ? startingPosition
            : updatedPath[prevIndex - 1].position;
        let showChance = CHANCE_POSITIONS.includes(newPosition);

        setDirection(-1);
        setCurrentCardIndex(prevIndex);
        setCurrentStepIndex(prevIndex);
        setCurrentProgressStep(prevIndex);
        setCurrentPosition(newPosition);
        setShowChancePrompt(showChance);

        return updatedPath;
      });

      setForceUpdate((prev) => !prev);
    } else if (currentStepIndex === 0) {
      setDirection(-1);
      setCurrentCardIndex(-1);
      setCurrentStepIndex(-1);
      setCurrentProgressStep(-1);
      setCurrentPosition(startingPosition);
      setShowChancePrompt(CHANCE_POSITIONS.includes(startingPosition));
      setForceUpdate((prev) => !prev);
    }
  }, [
    currentStepIndex,
    calculatedPath,
    startingPosition,
    CHANCE_POSITIONS,
    setCurrentPosition,
    setShowChancePrompt,
  ]);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: handleNextStep,
    onSwipedRight: handlePreviousStep,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const handleUndoAdjustment = useCallback(() => {
    const adjustment = adjustments.find(
      (adj) => adj.stepIndex === currentStepIndex
    );
    if (adjustment) {
      setCalculatedPath((prevPath) => {
        let updatedPath = [...prevPath];
        updatedPath[adjustment.stepIndex] = {
          ...updatedPath[adjustment.stepIndex],
          isRailroadMove: false,
          isPositionAdjusted: false,
          position: adjustment.originalPosition,
          originalPosition: undefined,
        };

        // Recalculate the path from this step onwards
        const remainingRolls = rolls.slice(adjustment.stepIndex);
        const newPath = calculateRollPath(
          adjustment.originalPosition,
          targetPositions,
          selectedCalculationMultiplier,
          activeMultipliers,
          remainingRolls,
          40
        );

        updatedPath = [
          ...updatedPath.slice(0, adjustment.stepIndex),
          ...newPath.map((step, index) => ({
            ...step,
            step: adjustment.stepIndex + index + 1,
          })),
        ];

        return updatedPath;
      });
      setCurrentPosition(adjustment.originalPosition);
      setAdjustments((prev) =>
        prev.filter((adj) => adj.stepIndex !== currentStepIndex)
      );
      setForceUpdate((prev) => !prev);
    }
  }, [
    adjustments,
    currentStepIndex,
    rolls,
    targetPositions,
    selectedCalculationMultiplier,
    activeMultipliers,
    calculateRollPath,
  ]);

  const handleClearCalculation = useCallback(() => {
    setCalculatedPath([]);
    setCurrentStepIndex(-1);
    setCurrentPosition(startingPosition);
    setShowChancePrompt(false);
    setIsCalculated(false);
    setActiveMultipliers(MULTIPLIERS);
    setSelectedCalculationMultiplier(Math.max(...MULTIPLIERS));
    setCurrentProgressStep(0);
  }, [
    setCalculatedPath,
    setCurrentStepIndex,
    setCurrentPosition,
    setShowChancePrompt,
    setIsCalculated,
    setActiveMultipliers,
    setSelectedCalculationMultiplier,
    startingPosition,
  ]);

  const handleExportClick = () => {
    try {
      const csvData = exportGameStateToCSV(
        rolls.flat(),
        startingPosition,
        currentPosition,
        targetPositions,
        usedMultipliers,
        selectedToken
      );

      const csv = Papa.unparse(csvData);
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "monopoly_game_state.csv");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      setFeedbackMessage(error.message);
      setFeedbackModalOpen(true);
    }
  };
  const renderRollsTable = () => {
    const usedMultipliers = [
      ...new Set(rolls.flat().map((roll) => roll.multiplier)),
    ].sort((a, b) => a - b);

    const maxRollNumbers = usedMultipliers.reduce((acc, multiplier) => {
      acc[multiplier] = Math.max(
        0,
        ...rolls
          .flat()
          .filter((roll) => roll.multiplier === multiplier)
          .map((roll) => roll.roll)
      );
      return acc;
    }, {});

    const maxRollNumber = Math.min(
      Math.max(0, ...Object.values(maxRollNumbers)),
      2500
    );

    const rollNumbersToRender = Array.from(
      { length: maxRollNumber },
      (_, i) => i + 1
    );

    return (
      <div className="rolls">
        <h2>Rolls</h2>
        <div className="rolls-container">
          <table>
            <thead>
              <tr>
                <th>#</th>
                {usedMultipliers.map((multiplier) => (
                  <th key={multiplier}>x{multiplier}</th>
                ))}
                <th>-</th>
              </tr>
            </thead>
            <tbody>
              {rollNumbersToRender.map((rollNumber) => (
                <tr key={rollNumber}>
                  <td>{rollNumber}</td>
                  {usedMultipliers.map((multiplier) => {
                    const roll = rolls
                      .flat()
                      .find(
                        (r) =>
                          r.multiplier === multiplier && r.roll === rollNumber
                      );
                    return (
                      <td key={`${multiplier}-${rollNumber}`}>
                        <div className="roll-cell">
                          <span className="roll-value">
                            {roll ? roll.rollValue : ""}
                          </span>
                        </div>
                      </td>
                    );
                  })}
                  <td>
                    {rolls.flat().some((roll) => roll.roll === rollNumber) && (
                      <button
                        onClick={() => handleRemoveRoll(rollNumber)}
                        className="remove-roll-button"
                        aria-label="Remove roll"
                      >
                        -
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  const stepInfoContent = useMemo(() => {
    if (
      currentStepIndex === -1 ||
      !calculatedPath ||
      calculatedPath.length === 0
    ) {
      return (
        <animated.div className="press-next-message">
          <p>Press "ROLL" to start following the calculated path.</p>
          <div className="starting-position">
            <span>Starting Position:</span>
            <span>{TILE_NAMES[startingPosition]}</span>
          </div>
        </animated.div>
      );
    }

    return transitions((style, index) => {
      const step = calculatedPath[currentStepIndex];
      if (!step) return null;

      return (
        <StepInfo
          key={index}
          step={step}
          currentPosition={currentPosition} // This should be the numeric position, not the name
          isTargetHit={targetPositions.includes(step.position)}
          selectedCalculationMultiplier={selectedCalculationMultiplier}
          targetPositions={targetPositions}
          style={style}
          isRailroadMove={step.isRailroadMove}
          forceUpdate={forceUpdate}
          calculatedPath={calculatedPath}
        />
      );
    });
  }, [
    currentStepIndex,
    calculatedPath,
    currentPosition,
    startingPosition,
    targetPositions,
    selectedCalculationMultiplier,
    transitions,
    forceUpdate,
  ]);

  return (
    <GlobalHotKeys keyMap={keyMap} handlers={handlers}>
      <div className="container">
        <div className="header-and-board">
          <img
            src="/MGSMM.svg"
            alt="MGS Move Master"
            className="header-image"
          />
          <div className="dropdown-container">
            <div className="dropdown-item">
              <label htmlFor="token-select" className="dropdown-label">
                SELECT TOKEN
              </label>
              <CustomSelect
                id="token-select"
                options={TOKEN_NAMES.map((name) => ({
                  value: `/tokens/${name}`,
                  label: name.split(".")[0],
                }))}
                value={selectedToken}
                onChange={(value) => setSelectedToken(value)}
                isTokenSelect
              />
            </div>
            {!isCalculated && (
              <div className="dropdown-item">
                <label htmlFor="multiplier-select" className="dropdown-label">
                  MULTIPLIER
                </label>
                <MultiplierWheel
                  id="multiplier-select"
                  options={MULTIPLIERS.map((multiplier) => ({
                    value: multiplier,
                    label: `x${multiplier}`,
                  }))}
                  value={currentMultiplier}
                  onChange={(value) =>
                    handleMultiplierChange(
                      value,
                      setCurrentMultiplier,
                      setCurrentPosition,
                      positions,
                      startingPosition
                    )
                  }
                />
              </div>
            )}
          </div>

          {isCalculated && (
            <div className="calculation-controls">
              <MultiplierManager
                activeMultipliers={activeMultipliers}
                inactiveMultipliers={inactiveMultipliers}
                onToggleMultiplier={handleToggleMultiplier}
              />
            </div>
          )}

          <div className="board-container">
            <RollInfo />
            <div className="board-wrapper" ref={boardRef}>
              <MonopolyBoard
                currentPosition={currentPosition}
                targetPositions={targetPositions}
                onSpaceClick={handleSpaceClick}
                isSelectingStart={isSelectingStart && !isCalculated}
                isSelectingTargets={isSelectingTargets || isAdjustingTargets}
                isAdjustingPosition={isAdjustingPosition}
                isCalculated={isCalculated}
                token={selectedToken}
                onRollClick={handleNextStep}
                onPreviousClick={handlePreviousStep}
                currentStepIndex={currentStepIndex}
                calculatedPath={calculatedPath}
                forceUpdate={forceUpdate}
              />

              {isCalculated && (
                <>
                  <div className="follow-calculated-path-title">
                    <span>Follow</span>
                    <span>Calculated Path</span>
                  </div>
                  <div className="follow-calculated-path">
                    <ProgressBar
                      current={currentProgressStep + 1}
                      total={calculatedPath.length}
                      targetPositions={targetPositions}
                      calculatedPath={calculatedPath}
                      selectedCalculationMultiplier={
                        selectedCalculationMultiplier
                      }
                    />
                    <div className="step-info-wrapper" {...swipeHandlers}>
                      {stepInfoContent}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="board-controls">
            {!isCalculated ? (
              <>
                {showChancePrompt && (
                  <div className="chance-prompt">
                    <button
                      className="nearest-railroad-button"
                      onClick={handleMoveToRailroadClick}
                    >
                      Nearest Railroad ??
                    </button>
                  </div>
                )}
                <div className="manual-roll-input">
                  <div className="manual-input-wrapper">
                    <button
                      onClick={toggleManualInput}
                      className={isManualInputActive ? "depressed" : ""}
                      disabled={startingPosition === null}
                    >
                      {isManualInputActive
                        ? "Close Manual Input"
                        : "Manual Input"}
                    </button>
                  </div>
                  {isManualInputActive && (
                    <input
                      ref={inputRef}
                      type="text"
                      value={manualRollValue}
                      onChange={handleManualRollValueChange}
                      onKeyPress={handleKeyPress}
                      placeholder="Enter roll (2-12) and press Enter"
                    />
                  )}
                </div>
                <button
                  onClick={() => setIsSelectingStart(true)}
                  className={isSelectingStart ? "depressed" : ""}
                >
                  Select Starting Position
                </button>
                <button
                  onClick={handleTargetToggle}
                  className={isSelectingTargets ? "depressed" : ""}
                >
                  {isSelectingTargets
                    ? "Stop Selecting Targets"
                    : "Select Target Positions"}
                </button>
                <button onClick={handleUndoButtonClick} disabled={rolls.length === 0 || isCalculated}>
        Undo Last Roll
      </button>
              </>
            ) : null}
          </div>
        </div>

        {isCalculated && (
          <div className="calculated-roll-controls">
            <div className="chance-prompt">
              {showChancePrompt &&
              !adjustments.some((adj) => adj.stepIndex === currentStepIndex) ? (
                <button
                  className="nearest-railroad-button"
                  onClick={handleMoveToRailroadCalculatedPathClick}
                >
                  Nearest Railroad ??
                </button>
              ) : (
                adjustments.some(
                  (adj) => adj.stepIndex === currentStepIndex
                ) && (
                  <button
                    className="undo-adjustment-button"
                    onClick={handleUndoAdjustment}
                  >
                    Undo{" "}
                    {adjustments.find(
                      (adj) => adj.stepIndex === currentStepIndex
                    ).type === "railroad"
                      ? "Nearest Railroad"
                      : "Position Adjustment"}
                  </button>
                )
              )}
            </div>
            <button
              onClick={handleAdjustPositionClick}
              className={`adjust-position-button ${
                isAdjustingPosition ? "active" : ""
              }`}
            >
              ADJUST POSITION
            </button>
            <button
              onClick={handleAdjustTargetsClick}
              className={`adjust-targets-button ${
                isAdjustingTargets ? "active" : ""
              }`}
            >
              ADJUST TARGETS
            </button>
            <button onClick={handleResetClick} className="reset-button">
              RESET
            </button>
            <button
              onClick={handleRemovePreviousRolls}
              disabled={currentStepIndex <= -1}
              className="remove-previous-rolls-button"
            >
              REMOVE PREVIOUS ROLLS
            </button>
          </div>
        )}

        <div className="controls-wrapper"></div>

        <div className="info">
          <p>
            Starting Position:{" "}
            {startingPosition !== null
              ? TILE_NAMES[startingPosition]
              : "Not selected"}
          </p>
          <p>
            Selected Targets:{" "}
            {targetPositions.length > 0
              ? targetPositions.map((pos) => TILE_NAMES[pos]).join(", ")
              : "None"}
          </p>
        </div>

        <div className="roll-calculation-container">
          <h2>Roll Calculation</h2>
          <div className="calculation-controls">
            <label htmlFor="desired-multiplier-select">Hit Targets With:</label>
            <div className="calculation-buttons">
              <CalculationMultiplierWheel
                id="desired-multiplier-select"
                options={MULTIPLIERS.map((multiplier) => ({
                  value: multiplier,
                  label: `x${multiplier}`,
                }))}
                value={selectedCalculationMultiplier}
                onChange={(value) => {
                  const newMultiplier = parseInt(value, 10);
                  if (activeMultipliers.includes(newMultiplier)) {
                    setSelectedCalculationMultiplier(newMultiplier);
                    if (isCalculated) {
                      handlecalculateRollPath(activeMultipliers, newMultiplier);
                    }
                  }
                }}
                allMultipliers={MULTIPLIERS}
                activeMultipliers={activeMultipliers}
              />
              <button
                onClick={handlecalculateRollPath}
                disabled={isCalculated}
                className="calculate-button"
              >
                Calculate Best Rolls
              </button>
              {calculatedPath.length > 0 && (
                <button
                  onClick={handleClearCalculation}
                  className="clear-button"
                >
                  Clear Calculation
                </button>
              )}
            </div>
          </div>
        </div>

        {calculatedPath.length > 0 ? (
          <CalculationResults
            calculatedPath={calculatedPath}
            targetPositions={targetPositions}
            selectedCalculationMultiplier={selectedCalculationMultiplier}
          />
        ) : (
          renderRollsTable()
        )}
        <div className="import-export-controls">
          <button className="export-button" onClick={handleExportClick}>
            Export Game State
          </button>
          <button className="import-button" onClick={handleImportClick}>
            Import Game State
          </button>
          <input
            type="file"
            accept=".csv"
            id="fileInput"
            style={{ display: "none" }}
            onChange={handleFileInputChange}
          />
        </div>

        <div className="clear-rolls-container">
          <button
            onClick={handleClearRollsClick}
            className="clear-rolls-button"
          >
            Clear Game State
          </button>
        </div>

        <ClearRollsConfirmation
          isOpen={showClearConfirmation}
          onClose={() => setShowClearConfirmation(false)}
          onConfirm={handleConfirmClearRolls}
        />
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <p>{importStatus}</p>
        </Modal>

        <RemoveRollsConfirmation
          isOpen={showRemoveRollsConfirmation}
          onClose={() => setShowRemoveRollsConfirmation(false)}
          onConfirm={confirmRemovePreviousRolls}
        />

        <FeedbackModal
          isOpen={feedbackModalOpen}
          message={feedbackMessage}
          onClose={() => setFeedbackModalOpen(false)}
          isError={isFeedbackError}
        />

        <ResetConfirmation
          isOpen={showResetConfirmation}
          onClose={() => setShowResetConfirmation(false)}
          onConfirm={handleConfirmReset}
        />
      {toastMessage && <Toast message={toastMessage} onClose={handleCloseToast} />}
      </div>
    </GlobalHotKeys>
  );
};

export default MonopolyGoLogger;
