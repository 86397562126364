import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { useSpring, animated, config } from "react-spring";
import "./CalculationMultiplierWheel.css";

const CalculationMultiplierWheel = ({
  options,
  value,
  onChange,
  allMultipliers,
  activeMultipliers,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const wheelRef = useRef(null);

  const handleSelect = useCallback(
    (option) => {
      if (activeMultipliers.includes(option.value)) {
        onChange(option.value);
        closeWheel();
      }
    },
    [onChange, activeMultipliers]
  );

  const closeWheel = useCallback(() => {
    if (isOpen && !isClosing) {
      setIsClosing(true);
      setTimeout(() => {
        setIsOpen(false);
        setIsClosing(false);
      }, 1000);
    }
  }, [isOpen, isClosing]);

  const toggleWheel = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wheelRef.current && !wheelRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const getColorClass = (multiplier) => {
    const baseClass = [200, 500, 1000].includes(multiplier)
      ? "purple"
      : "orange";
    if (!activeMultipliers.includes(multiplier)) {
      return `${baseClass} disabled`;
    }
    return baseClass;
  };

  const sortedMultipliers = useMemo(() => {
    const active = activeMultipliers.slice().sort((a, b) => a - b);
    const inactive = allMultipliers
      .filter((m) => !activeMultipliers.includes(m))
      .sort((a, b) => a - b);
    return [...active, ...inactive];
  }, [allMultipliers, activeMultipliers]);

  const buttonAnimation = useSpring({
    transform: isOpen ? "translateY(4px)" : "translateY(0px)",
    config: config.stiff,
  });

  const optionsAnimation = useSpring({
    opacity: isOpen ? 1 : 0,
    transform: isOpen ? "scale(1)" : "scale(0)",
    config: config.wobbly,
  });

  const backgroundAnimation = useSpring({
    opacity: isOpen ? 0.8 : 0,
    transform: isOpen ? "scale(1)" : "scale(0)",
    config: config.wobbly,
  });

  // Create spring animations for each option
  const optionAnimations = sortedMultipliers.map((_, index) => {
    const rotation = (360 / sortedMultipliers.length) * index;
    return useSpring({
      opacity: isOpen ? 1 : 0,
      transform: isOpen
        ? `rotate(${rotation}deg) translateY(-100px) rotate(-${rotation}deg)`
        : `rotate(${rotation}deg) translateY(0px) rotate(-${rotation}deg)`,
      config: config.wobbly,
      delay: index * 50,
    });
  });

  return (
    <div className="calculation-multiplier-wheel-container" ref={wheelRef}>
      <animated.button
        className={`multiplier-button ${isOpen ? "open" : ""}`}
        onClick={toggleWheel}
        style={buttonAnimation}
      >
        x{value}
      </animated.button>
      <animated.div
        className="multiplier-background"
        style={backgroundAnimation}
      />
      <animated.div className="multiplier-options" style={optionsAnimation}>
        {sortedMultipliers.map((multiplier, index) => {
          const isActive = activeMultipliers.includes(multiplier);
          const springProps = optionAnimations[index];

          return (
            <animated.button
              key={multiplier}
              className={`multiplier-option ${getColorClass(multiplier)} ${
                multiplier === value ? "selected" : ""
              }`}
              style={{
                ...springProps,
                "--rotation": `${(360 / sortedMultipliers.length) * index}deg`,
              }}
              onClick={() =>
                handleSelect({ value: multiplier, label: `x${multiplier}` })
              }
              disabled={!isActive}
            >
              x{multiplier}
            </animated.button>
          );
        })}
      </animated.div>
    </div>
  );
};

export default CalculationMultiplierWheel;
