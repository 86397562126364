import React from "react";

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  const modalOverlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000,
  };

  const modalContentStyle = {
    backgroundColor: "#1f0035",
    padding: "20px",
    borderRadius: "45px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontFamily: '"Quicksand", Arial, sans-serif',
    color: "white",
    boxShadow: "0 10px 20px rgba(0, 0, 0, 0.3)",
  };

  const closeButtonStyle = {
    marginTop: "20px",
    padding: "10px 25px",
    backgroundColor: "#fa00e9",
    color: "white",
    border: "none",
    borderRadius: "45px",
    cursor: "pointer",
    fontFamily: '"Quicksand", Arial, sans-serif',
    fontSize: "16px",
    boxShadow: "0 4px 0 #610054, 0 6px 10px rgba(0, 0, 0, 0.4)",
    transition: "all 0.1s ease",
    position: "relative",
    top: 0,
  };

  const handleCloseClick = (e) => {
    e.preventDefault();
    onClose();
  };

  const handleCloseHover = (e) => {
    e.currentTarget.style.backgroundColor = "#d400c9";
    e.currentTarget.style.boxShadow =
      "0 2px 0 #610054, 0 4px 8px rgba(0, 0, 0, 0.6)";
    e.currentTarget.style.top = "2px";
  };

  const handleCloseLeave = (e) => {
    e.currentTarget.style.backgroundColor = "#fa00e9";
    e.currentTarget.style.boxShadow =
      "0 4px 0 #610054, 0 6px 10px rgba(0, 0, 0, 0.4)";
    e.currentTarget.style.top = "0";
  };

  return (
    <div style={modalOverlayStyle}>
      <div style={modalContentStyle}>
        {children}
        <button
          onClick={handleCloseClick}
          onMouseEnter={handleCloseHover}
          onMouseLeave={handleCloseLeave}
          style={closeButtonStyle}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default Modal;
