import React from "react";
import { useSpring, animated } from "react-spring";
import "./ResetConfirmation.css";

const ResetConfirmation = ({ isOpen, onClose, onConfirm }) => {
  const animation = useSpring({
    opacity: isOpen ? 1 : 0,
    transform: isOpen ? "scale(1)" : "scale(0.5)",
    config: { tension: 300, friction: 10 },
  });

  if (!isOpen) return null;

  return (
    <div className="reset-overlay">
      <animated.div style={animation} className="reset-modal">
        <h2>Confirm Reset</h2>
        <p>Are you sure you want to reset the current calculation?</p>
        <div className="reset-buttons">
          <button className="reset-no" onClick={onClose}>
            No
          </button>
          <button className="reset-yes" onClick={onConfirm}>
            Yes
          </button>
        </div>
      </animated.div>
    </div>
  );
};

export default ResetConfirmation;
