export const calculateRollPath = (
  startingPosition,
  targetPositions,
  selectedCalculationMultiplier,
  activeMultipliers,
  rolls,
  spaces
) => {
  const calculateDistance = (from, to) => {
    return (to - from + spaces) % spaces;
  };

  let currentPosition = startingPosition;
  let path = [];
  const allTargets = [...targetPositions];

  let groupedRolls = rolls.map((rollGroup) =>
    rollGroup.filter((roll) => activeMultipliers.includes(roll.multiplier))
  );

  const addRollToPath = (roll) => {
    const newPosition = (currentPosition + roll.rollValue) % spaces;
    path.push({
      step: path.length + 1,
      multiplier: roll.multiplier,
      rollValue: roll.rollValue,
      originalPosition: newPosition,
      position: newPosition,
      tileName:
        allTargets.includes(newPosition) &&
        roll.multiplier === selectedCalculationMultiplier
          ? newPosition
          : undefined,
    });
    currentPosition = newPosition;
  };

  const findPathToNextTarget = (maxDepth = 100) => {
    const queue = [
      {
        position: currentPosition,
        path: [],
        rollIndex: 0,
      },
    ];
    const visited = new Set();

    while (queue.length > 0) {
      const { position, path, rollIndex } = queue.shift();

      if (rollIndex >= groupedRolls.length) continue;

      const key = `${position}-${rollIndex}`;
      if (visited.has(key)) continue;
      visited.add(key);

      for (const roll of groupedRolls[rollIndex]) {
        const newPosition = (position + roll.rollValue) % spaces;
        const newPath = [...path, roll];

        if (
          roll.multiplier === selectedCalculationMultiplier &&
          allTargets.includes(newPosition)
        ) {
          return newPath;
        }

        if (roll.multiplier !== selectedCalculationMultiplier) {
          queue.push({
            position: newPosition,
            path: newPath,
            rollIndex: rollIndex + 1,
          });
        }
      }

      if (visited.size > maxDepth) {
        return null; // Prevent excessive iterations
      }
    }

    return null;
  };

  while (allTargets.length > 0 && groupedRolls.length > 0) {
    const pathToNextTarget = findPathToNextTarget();

    if (pathToNextTarget === null) {
      break;
    }

    for (const roll of pathToNextTarget) {
      addRollToPath(roll);
      groupedRolls.shift();

      if (
        roll.multiplier === selectedCalculationMultiplier &&
        allTargets.includes(currentPosition)
      ) {
        break;
      }
    }
  }

  return path;
};
