import {
  SPACES,
  RAILROAD_POSITIONS,
  TILE_NAMES,
  MULTIPLIERS,
} from "./constants";
import { calculateRollPath } from "./calculateRollPath";

export const findNearestRailroad = (position) => {
  const distances = RAILROAD_POSITIONS.map(
    (railroad) => (railroad - position + SPACES) % SPACES
  );
  const minDistance = Math.min(...distances);
  const nearestRailroad = RAILROAD_POSITIONS[distances.indexOf(minDistance)];
  return nearestRailroad;
};

export const handleMoveToRailroad = (
  currentPosition,
  setCurrentPosition,
  setPositions,
  currentMultiplier,
  setShowChancePrompt
) => {
  const nearestRailroad = findNearestRailroad(currentPosition);
  setCurrentPosition(nearestRailroad);
  setPositions((prev) => ({
    ...prev,
    [currentMultiplier]: nearestRailroad,
  }));
  setShowChancePrompt(false);
};

export const handleMoveToRailroadCalculatedPath = (
  currentPosition,
  calculatedPath,
  currentStepIndex,
  rolls,
  targetPositions,
  selectedCalculationMultiplier,
  activeMultipliers
) => {
  const nearestRailroad = findNearestRailroad(currentPosition);

  let newCalculatedPath = [...calculatedPath];

  if (Array.isArray(calculatedPath) && calculatedPath.length > 0) {
    newCalculatedPath[currentStepIndex] = {
      ...newCalculatedPath[currentStepIndex],
      originalPosition: currentPosition,
      position: nearestRailroad,
      tileName: TILE_NAMES[nearestRailroad],
      isRailroadMove: true,
    };

    const remainingRolls = rolls.slice(currentStepIndex);
    const recalculatedPath = calculateRollPath(
      nearestRailroad,
      targetPositions,
      selectedCalculationMultiplier,
      activeMultipliers,
      remainingRolls,
      SPACES
    );

    newCalculatedPath = [
      ...newCalculatedPath.slice(0, currentStepIndex),
      ...recalculatedPath.map((step, index) => ({
        ...step,
        step: currentStepIndex + index + 1,
      })),
    ];
  }

  return {
    newCalculatedPath,
    railroadPosition: nearestRailroad,
  };
};
