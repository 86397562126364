import { MULTIPLIERS } from "./constants";

export const removePreviousRolls = (
  rolls,
  currentStepIndex,
  calculatedPath,
  setRolls,
  setPositions,
  setUsedMultipliers,
  setActiveMultipliers
) => {
  // Determine the new starting position from the calculated path
  // Use the position one step behind the current index
  const newStartingPosition =
    currentStepIndex > 0
      ? calculatedPath[currentStepIndex - 1].position
      : calculatedPath[0].position;

  setRolls((prevRolls) => {
    // Remove all roll groups up to the currentStepIndex
    const updatedRolls = prevRolls.slice(currentStepIndex);

    // Renumber the remaining rolls
    const renumberedRolls = updatedRolls.map((group, groupIndex) =>
      group.map((roll) => ({
        ...roll,
        roll: groupIndex + 1,
      }))
    );

    // Update positions
    const newPositions = {};
    const flatRolls = renumberedRolls.flat();
    flatRolls.forEach((roll) => {
      newPositions[roll.multiplier] = roll.to;
    });

    // Set the position for multipliers not in the remaining rolls
    MULTIPLIERS.forEach((multiplier) => {
      if (!newPositions.hasOwnProperty(multiplier)) {
        newPositions[multiplier] = newStartingPosition;
      }
    });

    setPositions(newPositions);

    // Update used multipliers and active multipliers
    const multipliers = new Set(flatRolls.map((roll) => roll.multiplier));
    setUsedMultipliers(Array.from(multipliers));
    setActiveMultipliers(Array.from(multipliers));

    return renumberedRolls;
  });

  return newStartingPosition;
};
