export const SPACES = 40;
export const MULTIPLIERS = [1, 2, 3, 5, 10, 20, 50, 100, 200, 500, 1000];
export const RAILROAD_POSITIONS = [5, 15, 25, 35];
export const CHANCE_POSITIONS = [7, 22, 36];

export const spacePositions = [
  { x: 757, y: 757, width: 90, height: 90 }, // GO (0)
  { x: 700.5, y: 757, width: 55, height: 90 }, // Old Kent Road (1)
  { x: 642.5, y: 757, width: 55, height: 90 }, // Community Chest (2)
  { x: 586, y: 757, width: 55, height: 90 }, // Whitechapel Road (3)
  { x: 529.5, y: 757, width: 55, height: 90 }, // Income Tax (4)
  { x: 472.5, y: 757, width: 55, height: 90 }, // Kings Cross Station (5)
  { x: 416, y: 757, width: 55, height: 90 }, // The Angel Islington (6)
  { x: 358.5, y: 757, width: 55, height: 90 }, // Chance (7)
  { x: 302, y: 757, width: 55, height: 90 }, // Euston Road (8)
  { x: 245.5, y: 757, width: 55, height: 90 }, // Pentonville Road (9)
  { x: 153, y: 757, width: 90, height: 90 }, // Jail (10)
  { x: 153, y: 700, width: 90, height: 55 }, // Pall Mall (11)
  { x: 153, y: 643.5, width: 90, height: 55 }, // Electric Company (12)
  { x: 153, y: 586.5, width: 90, height: 55 }, // Whitehall (13)
  { x: 153, y: 529.5, width: 90, height: 55 }, // Northumberland Ave (14)
  { x: 153, y: 472.5, width: 90, height: 55 }, // Marylebone Station (15)
  { x: 153, y: 416, width: 90, height: 55 }, // Bow Street (16)
  { x: 153, y: 358.5, width: 90, height: 55 }, // Community Chest (17)
  { x: 153, y: 302.5, width: 90, height: 55 }, // Marlborough Street (18)
  { x: 153, y: 244.5, width: 90, height: 55 }, // Vine Street (19)
  { x: 153, y: 153, width: 90, height: 90 }, // Free Parking (20)
  { x: 245.5, y: 153, width: 54, height: 90 }, // Strand (21)
  { x: 302.5, y: 153, width: 54, height: 90 }, // Chance (22)
  { x: 359.5, y: 153, width: 54, height: 90 }, // Fleet Street (23)
  { x: 416, y: 153, width: 54, height: 90 }, // Trafalgar Square (24)
  { x: 473, y: 153, width: 54, height: 90 }, // Fenchurch St Station (25)
  { x: 530, y: 153, width: 54, height: 90 }, // Leicester Square (26)
  { x: 586.5, y: 153, width: 54, height: 90 }, // Coventry Street (27)
  { x: 643.5, y: 153, width: 54, height: 90 }, // Water Works (28)
  { x: 700, y: 153, width: 54, height: 90 }, // Piccadilly (29)
  { x: 757, y: 153, width: 90, height: 90 }, // Go To Jail (30)
  { x: 757, y: 245.5, width: 90, height: 54 }, // Regent Street (31)
  { x: 757, y: 302.5, width: 90, height: 54 }, // Oxford Street (32)
  { x: 757, y: 359.25, width: 90, height: 54 }, // Community Chest (33)
  { x: 757, y: 416.5, width: 90, height: 54 }, // Bond Street (34)
  { x: 757, y: 473.5, width: 90, height: 54 }, // Liverpool St Station (35)
  { x: 757, y: 530, width: 90, height: 54 }, // Chance (36)
  { x: 757, y: 586.5, width: 90, height: 54 }, // Park Lane (37)
  { x: 757, y: 643.5, width: 90, height: 54 }, // Super Tax (38)
  { x: 757, y: 700.5, width: 90, height: 54 }, // Mayfair (39)
];

export const TILE_NAMES = {
  0: "GO!",
  1: "Mediterranean Avenue",
  2: "Community Chest 1",
  3: "Baltic Avenue",
  4: "Income Tax",
  5: "Reading Railroad",
  6: "Oriental Avenue",
  7: "Chance 1",
  8: "Vermont Avenue",
  9: "Connecticut Avenue",
  10: "Just Visiting",
  11: "St. Charles Place",
  12: "Electric Company",
  13: "States Avenue",
  14: "Virginia Avenue",
  15: "Pennsylvania Railroad",
  16: "St. James Place",
  17: "Community Chest 2",
  18: "Tennessee Avenue",
  19: "New York Avenue",
  20: "Free Parking",
  21: "Kentucky Avenue",
  22: "Chance 2",
  23: "Indiana Avenue",
  24: "Illinois Avenue",
  25: "B&O Railroad",
  26: "Atlantic Avenue",
  27: "Ventnor Avenue",
  28: "Water Works",
  29: "Marvin Gardens",
  30: "Jail",
  31: "Pacific Avenue",
  32: "North Carolina Avenue",
  33: "Community Chest 3",
  34: "Pennsylvania Avenue",
  35: "Short Line Railroad",
  36: "Chance 3",
  37: "Park Place",
  38: "Luxury Tax",
  39: "Boardwalk",
};

export const TOKEN_NAMES = [
  "Aeroplane.png",
  "Alpine Hat.png",
  "American Eagle.png",
  "Avocado.png",
  "Bank Pewter.png",
  "Basic House.png",
  "Beach Bucket.png",
  "Bunny Egg.png",
  "Bust Pewter.png",
  "Cake.png",
  "Captains Hat.png",
  "Car Free Parking Golden.png",
  "Car Free Parking.png",
  "Cat Statue.png",
  "Cat.png",
  "Chubby Cheeks.png",
  "Chocolate Cupcake.png",
  "Classic Pewter.png",
  "Coconut.png",
  "Corgi.png",
  "Crown.png",
  "Cupid Lizzie.png",
  "Cyclops.png",
  "Discus Thrower Gold.png",
  "Discus Thrower.png",
  "Diving Helmet.png",
  "Dolphin.png",
  "Dragster.png",
  "Duck Pewter.png",
  "Duck Tophat.png",
  "Faberge Egg.png",
  "Fluffy White.png",
  "Ghost Car.png",
  "Gingerbread Man.png",
  "Golfball Pewter.png",
  "Gnome.png",
  "Grand Marshal.png",
  "Hammer Gold.png",
  "Hammer.png",
  "Hippo.png",
  "Hot Dog Pewter.png",
  "Ice Skate.png",
  "Icecream Truck.png",
  "JackOLantern.png",
  "Jester.png",
  "Lion Gold.png",
  "Lion.png",
  "Martian PegE.png",
  "MBust Gold.png",
  "MrM 1stAnniversary.png",
  "MrM Cupid.png",
  "MrM Disco Gold.png",
  "MrM Disco.png",
  "MrM Figurine Pewter.png",
  "MrM Ghost.png",
  "MrM Leprechaun.png",
  "MrM Rockstar.png",
  "MrMech.png",
  "MsM PopStar.png",
  "Peg-E Muscle Car.png",
  "Peg-E.png",
  "Peacock Pewter.png",
  "Penguin Pewter.png",
  "Pharaoh Scottie.png",
  "Piano.png",
  "Piggy Bank Pewter.png",
  "Piggy Pot.png",
  "Plinko Toy.png",
  "Pocket Watch.png",
  "Pumpkin Pie.png",
  "Puffer Fish.png",
  "Rabbit Robot.png",
  "Rabbit.png",
  "Red Car.png",
  "Robber Pewter.png",
  "Robo Penguin.png",
  "Sandman.png",
  "Santa Hat.png",
  "Santasled Gold.png",
  "Santasled.png",
  "Scottie Astronaut.png",
  "Scottie Birthday.png",
  "Scottie FineDining.png",
  "Scottie Pewter.png",
  "Scottie Plaid.png",
  "Scottie Skate.png",
  "Scottie Sleeping.png",
  "Shell.png",
  "Sleeping Pewter.png",
  "Smile.png",
  "Snowman.png",
  "Sofia.png",
  "Sombrero.png",
  "Speedy Scottie.png",
  "St Patricks Cat.png",
  "Strawberry Cake.png",
  "Sweet Heart Cake.png",
  "The Tycoon Racer.png",
  "Tiara.png",
  "Train Boxcar Pewter.png",
  "Train Celebration.png",
  "Trex Pewter.png",
  "Trex.png",
  "Trumpet Pewter.png",
  "Turkey Pewter.png",
  "Turkey.png",
  "UFO.png",
  "Violin.png",
  "Vinyl.png",
  "Whisk Pewter.png",
  "Witch Hat.png",
  "Yacht Gold.png",
  "Yacht.png",
];
