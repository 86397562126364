import React from "react";
import { GameProvider } from "./GameContext";
import MonopolyGoLogger from "./MonopolyGoLogger";

const App = () => (
  <GameProvider>
    <MonopolyGoLogger />
  </GameProvider>
);

export default App;
