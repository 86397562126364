import React, { useState, useEffect } from "react";
import "./MultiplierManager.css";
import { MULTIPLIERS } from "./constants";

const MultiplierManager = ({
  activeMultipliers,
  inactiveMultipliers,
  onToggleMultiplier,
}) => {
  const [visibleMultipliers, setVisibleMultipliers] = useState([]);

  useEffect(() => {
    // Combine active and inactive multipliers for visibility
    const allMultipliers = [
      ...new Set([...activeMultipliers, ...inactiveMultipliers]),
    ];
    setVisibleMultipliers(allMultipliers.sort((a, b) => a - b));
  }, [activeMultipliers, inactiveMultipliers]);

  return (
    <div className="multiplier-manager">
      <h3>Manage Active Multipliers</h3>
      <div className="multiplier-toggles">
        {visibleMultipliers.map((multiplier) => {
          const isActive = activeMultipliers.includes(multiplier);
          const isDisabled = isActive && activeMultipliers.length === 1;

          return (
            <div
              key={`multiplier-${multiplier}`}
              className="multiplier-toggle-container"
            >
              <span className="multiplier-label">x{multiplier}</span>
              <label
                className={`multiplier-toggle ${isActive ? "active" : ""}`}
              >
                <input
                  type="checkbox"
                  checked={isActive}
                  onChange={() => onToggleMultiplier(multiplier)}
                  disabled={isDisabled}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MultiplierManager;
