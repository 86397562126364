import React from "react";
import { useSpring, animated } from "react-spring";
import "./RemoveRollsConfirmation.css";

const RemoveRollsConfirmation = ({ isOpen, onClose, onConfirm }) => {
  const animation = useSpring({
    opacity: isOpen ? 1 : 0,
    transform: isOpen ? "scale(1)" : "scale(0.5)",
    config: { tension: 300, friction: 10 },
  });

  if (!isOpen) return null;

  return (
    <div className="remove-rolls-overlay">
      <animated.div style={animation} className="remove-rolls-modal">
        <h2>Confirm Remove Previous Rolls</h2>
        <p>
          Are you sure you want to remove all rolls before the current step?
        </p>
        <div className="remove-rolls-buttons">
          <button className="remove-rolls-no" onClick={onClose}>
            No
          </button>
          <button className="remove-rolls-yes" onClick={onConfirm}>
            Yes
          </button>
        </div>
      </animated.div>
    </div>
  );
};

export default RemoveRollsConfirmation;
