import React, { useState, useEffect, useRef } from "react";
import "./CustomSelect.css";

const CustomSelect = ({ options, value, onChange, id, isTokenSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(
    options.findIndex((option) => option.value === value)
  );
  const ref = useRef();
  const optionsRef = useRef();

  const handleSelect = (option, index) => {
    onChange(option.value);
    setSelectedIndex(index);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isOpen && optionsRef.current) {
      const selectedOption = optionsRef.current.querySelector(".selected");
      if (selectedOption) {
        selectedOption.scrollIntoView({ block: "nearest" });
      }

      // Add cascading animation to options
      const options = optionsRef.current.querySelectorAll(".custom-option");
      options.forEach((option, index) => {
        option.style.animationDelay = `${index * 0.1}s`;
      });
    }
  }, [isOpen]);

  return (
    <div
      className={`custom-select-container ${
        isTokenSelect ? "token-select" : "multiplier-select"
      }`}
      ref={ref}
    >
      <div
        className={`custom-select ${isOpen ? "open" : ""}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        {isTokenSelect ? (
          <img src={value} alt="selected token" className="token-img" />
        ) : (
          options.find((option) => option.value === value)?.label || value
        )}
      </div>
      <div
        className={`custom-options ${isOpen ? "open" : ""}`}
        ref={optionsRef}
      >
        {options.map((option, index) => (
          <div
            key={index}
            className={`custom-option ${
              index === selectedIndex ? "selected" : ""
            }`}
            onClick={() => handleSelect(option, index)}
          >
            {isTokenSelect ? (
              <img
                src={option.value}
                alt={option.label}
                className="token-img"
              />
            ) : (
              option.label
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomSelect;
