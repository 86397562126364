import React, {
  createContext,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from "react";
import { MULTIPLIERS } from "./constants";

export const GameContext = createContext();

export const GameProvider = ({ children }) => {
  const [startingPosition, setStartingPosition] = useState(null);
  const [currentPosition, setCurrentPosition] = useState(null);
  const [rolls, setRolls] = useState([[]]);
  const [positions, setPositions] = useState({});
  const [currentMultiplier, setCurrentMultiplier] = useState(1);
  const [isSelectingStart, setIsSelectingStart] = useState(true);
  const [isSelectingTargets, setIsSelectingTargets] = useState(false);
  const [targetPositions, setTargetPositions] = useState([]);
  const [usedMultipliers, setUsedMultipliers] = useState(new Set([1]));
  const [calculatedPath, setCalculatedPath] = useState([]);
  const [showChancePrompt, setShowChancePrompt] = useState(false);
  const [selectedToken, setSelectedToken] = useState(`tokens/Aeroplane.png`);
  const [isAdjustingPosition, setIsAdjustingPosition] = useState(false);
  const [isAdjustingTargets, setIsAdjustingTargets] = useState(false);
  const [isCalculated, setIsCalculated] = useState(false);
  const [currentRollInfo, setCurrentRollInfo] = useState(null);
  const [selectedCalculationMultiplier, setSelectedCalculationMultiplier] =
    useState(1);
  const [activeMultipliers, setActiveMultipliers] = useState([...MULTIPLIERS]);

  useEffect(() => {
    // Load game state from localStorage when the component mounts
    const savedState = localStorage.getItem("monopolyGameState");
    if (savedState) {
      const parsedState = JSON.parse(savedState);
      setStartingPosition(parsedState.startingPosition);
      setCurrentPosition(parsedState.currentPosition);
      setRolls(parsedState.rolls);
      setPositions(parsedState.positions);
      setCurrentMultiplier(parsedState.currentMultiplier);
      setIsSelectingStart(parsedState.isSelectingStart);
      setIsSelectingTargets(parsedState.isSelectingTargets);
      setTargetPositions(parsedState.targetPositions);
      setUsedMultipliers(new Set(parsedState.usedMultipliers));
      setCalculatedPath(parsedState.calculatedPath);
      setShowChancePrompt(parsedState.showChancePrompt);
      setSelectedToken(parsedState.selectedToken);
      setActiveMultipliers(parsedState.activeMultipliers);
      setIsCalculated(parsedState.isCalculated);
      setSelectedCalculationMultiplier(
        parsedState.selectedCalculationMultiplier
      );
      setIsAdjustingPosition(parsedState.isAdjustingPosition);
      setIsAdjustingTargets(parsedState.isAdjustingTargets);
      setCurrentRollInfo(parsedState.currentRollInfo);
    }
  }, []);

  const saveGameState = useCallback(() => {
    try {
      const gameState = {
        startingPosition,
        currentPosition,
        rolls,
        positions,
        currentMultiplier,
        isSelectingStart,
        isSelectingTargets,
        targetPositions,
        usedMultipliers: Array.from(usedMultipliers),
        calculatedPath,
        showChancePrompt,
        selectedToken,
        activeMultipliers,
        isCalculated,
        selectedCalculationMultiplier,
        isAdjustingPosition,
        isAdjustingTargets,
        currentRollInfo,
      };
      localStorage.setItem("monopolyGameState", JSON.stringify(gameState));
    } catch (error) {
      console.error("Failed to save game state to localStorage:", error);
    }
  }, [
    startingPosition,
    currentPosition,
    rolls,
    positions,
    currentMultiplier,
    isSelectingStart,
    isSelectingTargets,
    targetPositions,
    usedMultipliers,
    calculatedPath,
    showChancePrompt,
    selectedToken,
    activeMultipliers,
    isCalculated,
    selectedCalculationMultiplier,
    isAdjustingPosition,
    isAdjustingTargets,
    currentRollInfo,
  ]);

  // Use a debounced version of saveGameState to reduce the number of writes
  useEffect(() => {
    const timeoutId = setTimeout(saveGameState, 1000); // 1 second delay
    return () => clearTimeout(timeoutId);
  }, [saveGameState]);

  useEffect(() => {
    console.log(
      `selectedCalculationMultiplier changed to: ${selectedCalculationMultiplier}`
    );
  }, [selectedCalculationMultiplier]);

  const updateSelectedCalculationMultiplier = useCallback((newValue) => {
    console.log(
      `Attempting to update selectedCalculationMultiplier to: ${newValue}`
    );
    setSelectedCalculationMultiplier(newValue);
  }, []);

  const contextValue = useMemo(
    () => ({
      startingPosition,
      setStartingPosition,
      currentPosition,
      setCurrentPosition,
      rolls,
      setRolls,
      positions,
      setPositions,
      currentMultiplier,
      setCurrentMultiplier,
      isSelectingStart,
      setIsSelectingStart,
      isSelectingTargets,
      setIsSelectingTargets,
      targetPositions,
      setTargetPositions,
      usedMultipliers,
      setUsedMultipliers,
      selectedCalculationMultiplier,
      setSelectedCalculationMultiplier,
      calculatedPath,
      setCalculatedPath,
      showChancePrompt,
      setShowChancePrompt,
      selectedToken,
      setSelectedToken,
      activeMultipliers,
      setActiveMultipliers,
      isAdjustingPosition,
      setIsAdjustingPosition,
      isAdjustingTargets,
      setIsAdjustingTargets,
      isCalculated,
      setIsCalculated,
      currentRollInfo,
      setCurrentRollInfo,
    }),
    [
      startingPosition,
      currentPosition,
      rolls,
      positions,
      currentMultiplier,
      isSelectingStart,
      isSelectingTargets,
      targetPositions,
      usedMultipliers,
      selectedCalculationMultiplier,
      calculatedPath,
      showChancePrompt,
      selectedToken,
      activeMultipliers,
      isAdjustingPosition,
      isAdjustingTargets,
      isCalculated,
      currentRollInfo,
    ]
  );

  return (
    <GameContext.Provider value={contextValue}>{children}</GameContext.Provider>
  );
};
