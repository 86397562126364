import React, { useContext, useMemo } from "react";
import { GameContext } from "./GameContext";

const RollInfo = React.memo(() => {
  const { currentMultiplier, rolls } = useContext(GameContext);

  const currentRoll = useMemo(() => {
    return rolls
      .flat()
      .filter((roll) => roll.multiplier === currentMultiplier)
      .pop();
  }, [rolls, currentMultiplier]);

  return (
    <div className="roll-info-container">
      {currentRoll ? (
        <>
          <p>Current Multiplier: x{currentMultiplier}</p>
          <p>Roll #: {currentRoll.roll}</p>
          <p>Roll Value: {currentRoll.rollValue}</p>
        </>
      ) : (
        <p className="no-roll-info">
          <span>No Roll</span>
          <span>Information</span>
          <span>Available</span>
        </p>
      )}
    </div>
  );
});

// This is optional, but it's good for debugging in development
RollInfo.displayName = "RollInfo";

export default RollInfo;
