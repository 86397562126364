import React, { useEffect, useState } from "react";
import { animated } from "react-spring";
import { TILE_NAMES } from "./constants";
import "./StepInfo.css";

const StepInfo = ({
  step,
  currentPosition,
  isTargetHit,
  selectedCalculationMultiplier,
  targetPositions,
  style,
  isRailroadMove,
  forceUpdate,
}) => {
  const [displayedCurrentPosition, setDisplayedCurrentPosition] =
    useState(currentPosition);

  useEffect(() => {
    setDisplayedCurrentPosition(currentPosition);
  }, [currentPosition, forceUpdate]);

  if (!step) return null;

  const isTargetPosition = targetPositions.includes(step.position);
  const isSelectedMultiplierHit =
    isTargetPosition && step.multiplier === selectedCalculationMultiplier;

  return (
    <animated.div
      style={{
        ...style,
        position: "absolute",
        width: "100%",
        height: "100%",
      }}
      className={`step-info multiplier-${step.multiplier} ${
        isSelectedMultiplierHit ? "target-hit" : ""
      }`}
    >
      <div className="rotatable-content">
        <div className="step-number">Next Roll: #{step.step}</div>
        <div className="multiplier-change">
          Next Multiplier: x{step.multiplier}
        </div>
        <div className="roll-info">Next Roll Value: {step.rollValue}</div>
        <div className="position">
          <span>Will Land on:</span>
          <span>{TILE_NAMES[step.position]}</span>
        </div>
        {isTargetPosition && (
          <div className="target-hit-text">
            <span>Target Will</span>
            <span>Be Hit</span>
          </div>
        )}
      </div>
    </animated.div>
  );
};

export default StepInfo;
